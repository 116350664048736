/**
Navbar Toggler
 */

#main-navbar {
    .navbar-toggler {
        border: none;
        background: transparent !important;
        outline: none !important;
        width: $navbar-toggler-width;
        box-shadow: none !important;
    }

    .navbar-toggler .icon-bar {
        display: block;
        width: 100%;
        border-radius: 1px;
        height: $navbar-toggler-thickness;
        transition: all .3s;
        position: relative;

        & + .icon-bar {
            margin-top: $navbar-toggler-spacer;
        }

        &.top-bar {
            animation: ease .7s navbar-toggler-top-2 forwards;
        }

        &.middle-bar {
            animation: ease .7s navbar-toggler-scaled-2 forwards;
        }

        &.bottom-bar {
            animation: ease .7s navbar-toggler-bottom-2 forwards;
        }
    }

    &.navbar-light .navbar-toggler .icon-bar {
        background-color: $primary;
    }

    &.navbar-dark .navbar-toggler .icon-bar {
        background-color: $white;
    }

    .navbar-toggler[aria-expanded="true"] .icon-bar {
        background: $primary;

        &.top-bar {
            animation: ease .7s navbar-toggler-top forwards;
        }

        &.middle-bar {
            animation: ease .7s navbar-toggler-scaled forwards;
        }

        &.bottom-bar {
            animation: ease .7s navbar-toggler-bottom forwards;
        }
    }
}

@keyframes navbar-toggler-top {
    0% {
        top: 0;
        transform: rotate(0);
    }

    50% {
        top: $navbar-toggler-y-center;
        transform: rotate(0);
    }

    100% {
        top: $navbar-toggler-y-center;
        transform: rotate(45deg);
    }
}

@keyframes navbar-toggler-top-2 {
    0% {
        top: $navbar-toggler-y-center;
        transform: rotate(45deg);
    }

    50% {
        top: $navbar-toggler-y-center;
        transform: rotate(0deg);
    }

    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes navbar-toggler-bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }
    50% {
        bottom: $navbar-toggler-y-center;
        transform: rotate(0);
    }
    100% {
        bottom: $navbar-toggler-y-center;
        transform: rotate(135deg);
    }
}

@keyframes navbar-toggler-bottom-2 {
    0% {
        bottom: $navbar-toggler-y-center;
        transform: rotate(135deg);
    }
    50% {
        bottom: $navbar-toggler-y-center;
        transform: rotate(0);
    }

    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes navbar-toggler-scaled {
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes navbar-toggler-scaled-2 {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
