.department {
    .card {
        transition: all 0.3s ease-out;
        border: 1px solid $white;
        height: 100%;
        min-height: 171px;

        &:hover {
            transition: all 0.3s ease-out;
            border: 1px solid $primary !important;

            h4 {
                color: $primary;
                transition: color 0.3s;
            }
        }

        h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            transition: color 0.3s;
        }
    }

    img {
        height: 63px;
        border-radius: 50%;
        box-shadow: 0 10px 26px 0 rgba(0, 0, 0, 0.1);
    }
}

#department-single-page {
    .department-show-title {
        i {
            height: 74px;
            width: 74px;
            background-color: rgba($primary, 0.10);
            position: relative;

            &:before {
                color: $primary;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        h4 {
            font-size: 22px;
        }
    }
}

.department-card {
    i {
        height: 74px;
        width: 74px;
        background-color: rgba($primary, 0.10);
        position: relative;

        &:before {
            color: $primary;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
