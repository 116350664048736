/**
Contact Us
 */

.contact-us-page {
    @media (min-width: 1111px) {
        margin-bottom: 5rem;
    }

    .form-control {
        box-shadow: unset;
        border-color: $grey-e6;
        background-color: transparent;
    }

    .contact-details {
        .far {
            color: $primary-color;
        }
    }

}
