/* montserrat-200 - latin - Extra Light */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/montserrat/Montserrat-ExtraLight.otf') format('opentype');
    src: local(''),
    url('../fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat/Montserrat-Regular.otf') format('opentype');
    src: local(''),
    url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* montserrat-500 - latin - Medium */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/montserrat/Montserrat-Medium.otf') format('opentype');
    src: local(''),
    url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* montserrat-600 - latin - Semi Bold */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat/Montserrat-SemiBold.otf') format('opentype');
    src: local(''),
    url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* montserrat-700 - latin - Bold */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat/Montserrat-Bold.otf') format('opentype');
    src: local(''),
    url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* montserrat-900 - latin - Black */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/montserrat/Montserrat-Black.otf') format('opentype');
    src: local(''),
    url('../fonts/montserrat/Montserrat-Black.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/montserrat/Montserrat-Black.woff') format('woff'), /* Modern Browsers */
    url('../fonts/montserrat/Montserrat-Black.ttf') format('truetype'); /* Safari, Android, iOS */
}


// Dhivehi Font Unicode in unicode ranges

/* montserrat-400 - dhivehi */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/thaana/MV-Faruma.otf') format('opentype');
    unicode-range: U+0780-07BF;
}

/* montserrat-500 - dhivehi */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/thaana/MV-Waheed.otf') format('opentype');
    unicode-range: U+0780-07BF;
}

/* montserrat-600 - dhivehi */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/thaana/MV-Waheed.otf') format('opentype');
    unicode-range: U+0780-07BF;
}

/* montserrat-700 - dhivehi */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/thaana/MV-Bodu.otf') format('opentype');
    unicode-range: U+0780-07BF;
}

/* montserrat-900 - dhivehi */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/thaana/MV-Bodu.otf') format('opentype');
    unicode-range: U+0780-07BF;
}
