.social-item {
    font-size: 25px;
    border-radius: 50%;
    color: #fff;
    transition: 0.5s all;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 5px;

    &:hover {
        background-color: $primary-color !important;
    }
}
