.carousel {
    height: calc(100vh);
    overflow: hidden;
    @media (min-width: 1112px) {
        margin-top: -$desktop-navbar-height;
    }
    @media (max-width: 1111px) {
        margin-top: -$mobile-navbar-height;
    }

    .carousel-indicators {
        margin: 0 auto 2rem auto;

        button {
            border-radius: 100%;
            display: block;
            margin: 10px 0;
            padding: 2px;

            &.active {
                border: solid $white 1px;
                -webkit-background-clip: content-box; /* for Safari */
                background-clip: content-box; /* for IE9+, Firefox 4+, Opera, Chrome */
            }
        }

        .active {

        }
    }

    .carousel-caption {
        position: absolute;
        right: 0;
        bottom: 16%;
        left: 0;
        z-index: 1;
        padding-top: 0;
        padding-bottom: 0;
        color: $carousel-caption-color;
        text-align: left;
        margin: 0 auto 0 auto;

        h1 {
            font-size: 40px;
            /*rtl:raw:
                font-size: 60px;
            */
            font-weight: $font-weight-bolder;
            line-height: 69px;
            margin-bottom: 24px;
        }
    }

    .carousel-inner {
        height: 100%;

        .carousel-item {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgb(0, 0, 102);
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5497549361541492) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .carousel {
        margin-top: -0.67rem;
    }
}
