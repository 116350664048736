/*Gallery*/
.gallery {
    position: relative;

    .black-gradient {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4497549361541492) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }

    .card {
        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            transition: 0.5s;
        }

        &:hover {
            img {
                filter: brightness(90%);
                transition: 0.5s;
            }
        }
    }

    .card-body {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;

        * {
            margin: 5px;
            color: #FFF;
        }

        h6 {
            font-weight: 400;
        }
    }
}

.post-item-datetime {
    font-size: 14px;
}

.download-accordion {
    border-radius: 15px !important;

    .accordion-button {
        border-radius: 15px !important;

        &:not(.collapsed) {
            border-radius: 15px 15px 0 0 !important;
        }

        &::after {
            position: absolute;
            top: 35%;
            right: 20px;
        }
    }
}

/*News*/
.news {
    .card {
        height: 440px;
        overflow: hidden;

        h5 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        img {
            width: 100%;
            height: 294px;
            object-fit: cover;
            transition: 0.5s;
        }

        /*.news-btn {
            height: 0;
            overflow: hidden;
            transition: 0.5s;
            i {
                padding-left: 5px;
                font-size: 10px;
                transition: 0.2s;
            }
            &:hover {
                i {
                    padding-left: 8px;
                    transition: 0.2s;
                }
            }
        }*/
        &:hover {
            img {
                transform: scale(1.1);
                transition: 0.5s;
            }

            /*.news-btn {
                height: 30px;
                transition: 1s;
            }*/
        }
    }

    @include media-breakpoint-down(md) {
        .card {
            height: 100%;
            /*.news-btn {
                height: 30px;
            }
            &:hover {
                img {
                    height: 250px;
                }
            }*/
        }
    }
}
