/**
Mapael
 */

.mapael .map {
    position: relative;
}

.mapael .mapTooltip {
    position: absolute;
    line-height: 100%;
    color: #747a80;
    display: none;
    font-size: 0.95rem;
    border-radius: 2px;
    padding: 0.7rem 1rem;
    z-index: 1000;
}
