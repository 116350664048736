/**
Select 2
 */

//@import 'node_modules/select2/src/scss/core';
@import 'node_modules/select2-bootstrap-5-theme/src/include-all';

.select2-container--bootstrap-5 {
    // Dropdown
    .select2-dropdown {
        //
        border: 0;
        box-shadow: $box-shadow-sm;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-name: slideUp;
        @include border-radius($dropdown-border-radius);

        // Search box
        .select2-search {
            // Search field
            .select2-search__field:focus {
                border-color: $input-border-color;
                box-shadow: none;
            }
        }

        // Items
        .select2-results__options {
            // Item
            .select2-results__option {
                font-weight: $font-weight-normal;
            }
        }
    }

    // Input
    .select2-selection {
        border: 1px solid $grey-e6;
        border-radius: 15px;
        box-shadow: none;
    }

    // Focused/Open Input
    &.select2-container--open,
    &.select2-container--focus {
        .select2-selection {
            box-shadow: none;
        }
    }

    // Hide bottom border when open and below
    &.select2-container--open.select2-container--below .select2-selection {
        border-bottom: none;
    }

    // Hide top border when open and above
    &.select2-container--open.select2-container--above .select2-selection {
        border-top: none;
    }

    .select2-selection--single {
        .select2-selection__rendered {
            /*rtl:raw:
                text-align: right;
            */
        }
    }

}
