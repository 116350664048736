@keyframes slideDown {
    0% {
        transform: translateY(-1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }

    0% {
        transform: translateY(-1rem);
        opacity: 0;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loadingBgAnimation {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}
