.post-show {
    .title-area {
        height: 90px;

        .post-item-icon {
            background-color: rgb($primary, 0.05);
            color: $primary;
            transition: all 0.3s;
            width: 90px;

            i {
                font-size: 40px;
                color: $primary;
                transition: all 0.3s;
            }
        }

        h3 {
            font-size: 1.125rem;
        }

        .status-box {
            font-size: .875rem;
        }

        .published-at {
            font-size: 14px;
            color: $grey-c5;
        }
    }

    .attachments {
        .card-body {
            padding: 33px 30px;
        }

        h4 {
            font-size: 1.25rem;
        }

        .file-size {
            font-size: .75rem;
        }

        hr {
            color: $grey-aa;
            height: 2px;
        }

        .attachment {
            & + .attachment {
                border-top: 1px $grey-ec solid;
            }
        }
    }

}
