/**
Navbar
 */

.search-icon {
    width: 1px;

    &.fa-times {
        padding-left: 4px;
    }

    &.fa-search {
        position: absolute;
        top: 16px;
        right: 32px;
        color: $grey-70;
        transition: all 0.3s;

        &.search-open {
            right: 332px;
            color: $accent;
        }
    }
}

.flag-thumb {
    width: 1.25rem;
}

.nav-lang {
    font-size: 1rem;
    color: $grey-c5;
}

.dropdown-menu {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: slideUp; // This comes from the _animations.scss file
    border-radius: 10px;
    box-shadow: 0 0 15px -10px #888888;
    z-index: 10000;
    padding: 5px;
    .dropdown-item {
        @include animate();
        color: $grey-aa;
        margin-bottom: 5px;
        font-weight: 500;
        border-radius: 7px;
        &.active {
            color: #fff;
            background-color: $primary;
        }
        &:hover {
            color: #fff;
            background-color: $primary;
        }
    }
}

.dropdown {
    .dropdown-toggle {
        font-size: .625rem;
        @include animate();

        &.show {
            transform: rotate(180deg);
        }
    }
    i {
        font-size: 14px;
    }
    &.show {
        .dropdown-toggle {
            transform: rotate(180deg);
        }
    }
}

.rounded-corners {
    border-radius: 15px !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

#main-navbar {
    padding-top: 0;
    background-color: $white;

    &:not(.header-nav) {
        .container-lg.py-2 {
            z-index: 1;

            &::before {
                content: "";
                width: 595px;
                height: 595px;
                position: absolute;
                top: -55%;
                left: 6%;
                @media (max-width: 1111px) {
                    width: 450px;
                    height: 450px;
                    top: 0;
                    left: -15%;
                }
                background-image: url("../img/header-bg-design.svg");
                background-repeat: no-repeat;
                background-position: top right;
                background-size: contain;
                z-index: -2;
            }

            &::after {
                content: "";
                width: 595px;
                height: 595px;
                position: absolute;
                bottom: -25%;
                right: 10%;
                @media (max-width: 1111px) {
                    width: 450px;
                    height: 450px;
                    bottom: 0;
                    right: -15%;
                }
                background-image: url("../img/header-bg-design.svg");
                background-repeat: no-repeat;
                background-position: bottom left;
                background-size: contain;
                z-index: -2;
            }
        }
    }

    &.header-nav {
        background-color: #051C45;
    }

    #primary-menu {
        transition: all 0.3s;
        opacity: 1;
        position: relative;

        &.search-open {
            right: 500px;
            opacity: 0;
        }
    }

    .container-lg {
        background-color: $white;
    }

    &,
    .nav-link {
        @include animate();
    }

    .nav-item {
        position: relative;
    }

    .navbar-logo {
        max-width: 155px;
        object-fit: contain;
    }

    .navbar-brand {
        white-space: normal;
    }

    .company-name {
        /*rtl:raw:
        font-size: 24px;
        */
        /*rtl:remove*/
        font-size: 1.5rem;
    }

    .nav-link {
        font-size: 15px;
        //padding-right: 2rem;
        padding-left: 1rem;
        color: $nav-link-color;
        font-weight: $font-weight-medium;
    }

    [id*="menu-item-"] {
        color: $white;
        @media (max-width: 1111px) {
            color: $nav-link-color;
            font-weight: $font-weight-bold !important;
        }

        &::before {
            content: "";
            height: 3px;
            border-radius: 25px;
            position: absolute;
            left: 1rem;
            bottom: 10px;
            width: 0;
            background-color: $accent;
            transition: 0.5s all ease;
        }

        &.active {
            color: $accent;

            &::before {
                width: 23px;
            }
        }

        &:hover {
            color: $accent;

            &::before {
                width: 23px;
            }
        }
    }

    .search-btn {
        background-color: #F6F6F6;
        border-radius: 25px;
        width: 50px;
        height: 50px;
        z-index: 100;
        position: relative;
    }

    .dropdown {
        position: relative;

        .nav-link {
            padding-right: $navbar-nav-link-padding-x + .9rem;
            padding-left: .9rem;
        }

        .dropdown-toggle {
            @media(max-width: 1111px) {
                color: #000;
            }
            @media(min-width: 1112px) {
                color: #fff;
            }
            text-decoration: none;
            position: absolute;
            padding: 0.8rem;
            top: .2rem;
            right: 0;
        }
    }

    .dropdown-menu {
        line-height: 1.8rem;
    }

    .dropdown-menu {
        line-height: 1.8rem;

        &.mobile-dropdown {
            border-left: solid 1px $grey-e6 !important;

            .level-2-child:before {
                content: '';
                border: 4px solid $accent-color;
                border-radius: 50rem;
                width: 6px;
                margin-right: 8px;
                font-size: 17px;
                display: inline-block;
                margin-top: -5px;
            }
        }
    }

    &.navbar-light {
        background-color: $white;

        .logo-white {
            display: none;
        }

        .dropdown-toggle {
            color: $light-grey;
        }
    }
}

// Mobile
@include media-breakpoint-down(lg) {
    .dropdown {
        .dropdown-toggle {
            font-size: .875rem;
        }
    }

    .flag-thumb {
        width: 1.875rem;
    }

    #main-navbar {
        padding-top: 0;
        background-color: $white;

        .navbar-toggler {
            .icon-bar {
                background-color: $primary;
            }
        }

        &.show {
            border-bottom-right-radius: .625rem;
            border-bottom-left-radius: .625rem;
        }

        .dropdown-menu {
            border: none;
            background-color: transparent;
            box-shadow: none;
        }

        .navbar-collapse {
            background-color: $navbar-mobile-bg;
        }
    }

    #primary-menu {
        transition: padding-right 0.3s;
        padding-right: 0;

        &.search-open {
            padding-right: 500px;
        }

        .nav-link {
            padding: $nav-link-padding-y !important;
            max-width: calc(100% - #{$navbar-nav-link-padding-x + .5rem});
        }

        .dropdown .dropdown-toggle {
            top: 4px;
        }

        .dropdown-menu {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(sm) {
    #main-navbar {
        .company-name {
            font-size: 0.85rem;
        }
    }
}

/* ============ desktop view ============ */
@media all and (min-width: 1112px) {
    .dropdown-menu li {
        position: relative;
    }

    .nav-item .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
    }

    .nav-item .submenu-left {
        right: 100%;
        left: auto;
    }

    .dropdown-menu > li:hover > .submenu {
        display: block;
    }
}

/* ============ desktop view .end// ============ */
