//
// LONG ARROW
//
/*!rtl:remove*/
.fa-long-arrow-end:before {
    content: "\f178";
}

/*!rtl:raw:
.fa-long-arrow-end:before {
    content: "\f177";
}
*/

/*!rtl:remove*/
.fa-long-arrow-start:before {
    content: "\f177";
}

/*!rtl:raw:
.fa-long-arrow-start:before {
    content: "\f178";
}
*/

//
// CHEVRON
//
/*!rtl:remove*/
.fa-chevron-end:before {
    content: "\f054";
}

/*!rtl:raw:
.fa-chevron-end:before {
    content: "\f053";
}
*/

/*!rtl:remove*/
.fa-chevron-start:before {
    content: "\f053";
}

/*!rtl:raw:
.fa-chevron-start:before {
    content: "\f054";
}
*/
