/*rtl:ignore*/
.breadcrumb {
    font-weight: $font-weight-medium;
    font-size: 14px;
    margin-bottom: 24px;
}

/*rtl:ignore*/
.breadcrumb-item {
    &:not(.active) {
        a {
            text-decoration: none !important;
            color: $accent !important;
            font-weight: bold;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $white !important;
            }
        }
    }

    &.active {
        font-weight: bold;
        color: $white;
    }

    & + .breadcrumb-item::before {
        color: $white;
    }
}
