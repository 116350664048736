/**
Typography
 */

///*!rtl:raw:
//body {
//    letter-spacing: 2px;
//}
//*/

.dv {
    font-family: $font-family-sans-serif;
    /*!rtl:btn-primary btn-slide-accent*/
    direction: rtl;
}

.en {
    font-family: $font-family-sans-serif;
    /*!rtl:ignore*/
    direction: ltr;
}

.dir-lrt {
    /*rtl:ignore*/
    direction: ltr;
}

.dir-rtl {
    /*rtl:ignore*/
    direction: rtl;
}

a {
    @include animate();
}

.block-list {
    list-style: none;
    color: #000;

    li:before {
        content: '';
        border: 4px solid $primary;
        border-radius: 50rem;
        width: 6px;
        margin-right: 8px;
        font-size: 17px;
        display: inline-block;
        margin-top: -5px;
        color: $accent-color;
    }

    li:after {
        content: '';
        display: block;
        clear: both;
    }
}

.underline-text:after {
    content: "";
    display: block;
    vertical-align: center;
    width: 75px;
    margin: 10px 0 0 0;
    border-top: 3.5px solid $accent-color;
}
