.bg-green-light {
    background-color: $accent;
}

body {
    background-color: #fff;
}

/*rtl:raw:
#org-desc::after {
    transform: rotateY(180deg);
}
#org-desc #org-desc-img-bg{
    transform: rotateY(180deg);
}
*/

.indented-text {
    display: flex;
    align-items: center;
    @media (max-width: 1111px) {
        flex-direction: column;
        &:before {
            content: '';
            width: 60px;
            height: 2px;
            margin-bottom: 10px;
            background-color: $accent;
            border-radius: 50rem;
        }
    }
    @media (min-width: 1112px) {
        &:before {
            content: '';
            width: 60px;
            height: 2px;
            margin-right: 10px;
            background-color: $accent;
            border-radius: 50rem;
        }
    }
}

.text-hover-white {
    &:hover {
        color: #FFF !important;
    }
}

.word-blue {
    color: #1B5EBE !important;
}

#org-desc {
    position: relative;
    padding-top: 4rem;
    @include media-breakpoint-down(lg) {
        padding-top: 3rem;
    }

    h1 {
        font-size: 30px;
        color: $black;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 50px;
    }

    #org-desc-img {
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            &:before {
                top: 39px;
                left: 0;
            }
        }
    }

    #org-static-img {
        height: 45%;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: -1;
        background-size: 125% !important;
        background-position-y: bottom !important;
        background-position-x: -97px !important;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
}

.homepage-title {
    background-color: $primary;
    padding: 15px 30px 15px 15px;
    color: #fff;
    border-radius: 0 50rem 50rem 0;
}

.latest-news, .latest-announcements, .latest-publications, .latest-jobs, .department-show {
    i {
        transition: all 0.3s;
    }

    .homepage-title {
        font-size: 25px;
        font-weight: bolder;
    }

    .homepage-more-link {
        a {
            color: $accent;

            i {
                color: $accent !important;
                transition: all 0.3s;
            }
        }
    }

    .homepage-more-link:hover {
        a {
            color: #fff !important;

            i {
                color: #fff !important;
                transform: translate(5px, 0);
            }
        }
    }
}

.filter-dropdown {
    .filter-toggle {
        i {
            height: 50px;
            width: 50px;
            background-color: $white;
            border-radius: 50rem;
            position: relative;
            transition: all 0.3s ease-in-out;

            &:before {
                color: $accent;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.1s ease-in-out;
            }
        }

        &.show {
            transform: rotate(0) !important;

            i {
                background-color: $accent;

                &:before {
                    color: $white;
                }
            }
        }
    }

    .card {
        width: 400px;
    }
}

#statsSection {
    background: rgb(0, 0, 0);
    background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%);
    margin-top: -1.5rem;
    position: relative;

    &:before {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        overflow: hidden;
        background-image: url("../img/stats-background.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.1;
        /*rtl:raw:
            transform: scale(1.2,1);
        */
    }

    .statistic-text {
        line-height: 69px;

        &:before {
            content: "";
            display: block;
            vertical-align: center;
            width: 94px;
            border-top: 3px solid $accent;
            border-radius: 25px;
        }
    }

    .stat-value {
        margin-top: -1rem;

        &:not(:first-child) {
            &:before {
                content: '';
                height: 70%;
                width: 1px;
                position: absolute;
                left: 0;
                top: 25%;
                background-color: $white;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .statistic-text {
            &:before {
                margin: 0 auto;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .stat-value {
            &:not(:first-child) {
                &:before {
                    height: 1px;
                    width: 40%;
                    top: 16px;
                    left: 31%;
                    /*rtl:raw:
                        right: 29%;
                    */
                }
            }
        }
    }

}

#publications-section {
}

#training {
    margin-top: -275px;
    margin-bottom: 80px;

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }

    #main-heading {
        font-size: 40px;
        color: $primary;
    }

    .col-lg-6:first-of-type {
        border-right: solid 1px #9FBDE6;
        padding: 0;
        @include media-breakpoint-down(md) {
            border: 0;
        }

        div {
            padding: 40px;
        }

        h1 {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 39px;
        }
    }

    .col-lg-6:last-of-type {
        padding: 30px 74px;
        @include media-breakpoint-down(md) {
            padding: 30px;
        }

        button {
            margin-top: 47px;
        }

        .underline-text {
            margin-bottom: 35px;
        }
    }
}

#companies-section {
    margin-bottom: 138px;

    nav {
        margin-bottom: 41px;
    }

    .nav-link {
        background-color: unset;
        text-align: left;
        font-size: 24px;
        font-weight: $font-weight-bold;
        color: $grey-c5;
        border: 0;

        &:hover {
            border: 0;
        }

        &:active {
            border: 0;
        }
    }

    .nav-tabs {
        border: 0;
        align-items: flex-start;
        justify-content: flex-start;

        .active {
            border: none;
            color: $black;

            &:after {
                content: "";
                display: block;
                vertical-align: center;
                width: 75px;
                margin: 10px 0 0 0;
                border-top: 3.5px solid $accent;
            }
        }
    }

    .tab-content {
        img {
            height: 100px;
            object-fit: contain;
            filter: grayscale(100%);

            &:hover {
                filter: grayscale(0);
            }
        }

        @include media-breakpoint-down(md) {
            img {
                margin-bottom: 45px;
            }
        }
    }
}

#mobile-app {
    position: relative;
    overflow: hidden;

    .bg-left {
        position: absolute;
        filter: brightness(0) invert(1);
        opacity: 0.2;
        top: -100px;
        left: -250px;
        width: 383.18px;
    }

    .bg-right {
        position: absolute;
        filter: brightness(0) invert(1);
        opacity: 0.2;
        bottom: -150px;
        right: -250px;
        width: 873.97px;
        z-index: 1;
        transform: rotateY(180deg);
    }

    .col-lg-4 {
        z-index: 2;

        .d-flex {
            img {
                height: 35px;
                object-fit: contain;
                filter: brightness(0) invert(1);
            }

            h1 {
                color: $white;
                font-size: 30px;
            }

            justify-content: center;
        }

        text-align: center;
    }

    .col-lg-3 {
        z-index: 2;

        .download-box {
            border: solid 1px $white;

            h5 {
                color: $white;
            }

            i {
                color: $white;
                font-size: 35px;
            }

            &:hover {
                background-color: #FFF;

                h5, i {
                    color: $black;
                }
            }
        }

        img {
            width: 100%;
            height: 700px;
            object-fit: cover;
            object-position: 100% 0;
        }
    }

    /*mobile view*/
    @include media-breakpoint-down(md) {
        .container {
            padding: 0 70px;

            img {
                object-fit: cover;
                height: 500px;
            }
        }
        .bg-left {
            display: none;
        }
    }
    /*desktop view*/
    @include media-breakpoint-up(lg) {
        height: 400px;
        .row {
            height: 350px;
        }
        .col-lg-3 {
            img {
                margin-top: -120px;
                height: 400px;
                object-fit: cover;
                object-position: 100% 0;
            }
        }
        .col-lg-4 {
            .d-flex {
                justify-content: flex-start;
            }

            text-align: left;
        }
    }
}

/*statistics section*/
#Statistics-section {
    background-color: #F2F5F8;
    padding: 50px 0;

    .card {
        margin-bottom: 2rem;
    }

    .col-lg-3 {
        .card {
            i {
                font-size: 50px;
                color: $primary;
                margin: 0.4rem;
            }

            h4 {
                margin-right: 20%;
            }

            h1 {
                color: $primary;
            }

            position: relative;

            * {
                z-index: 2;
            }

            .bg-image {
                z-index: -1;
                position: absolute;
                font-size: 90px;
                right: 3px;
                bottom: 3px;
                color: #F2F5F8;
            }
        }
    }

    .col-lg-9 {
        @include media-breakpoint-down(md) {
            h1, h2 {
                font-size: 9px;
            }
            h4 {
                font-size: 7px;
            }
        }
    }
}
