/**
Layout
 */

#wrapper {
    min-height: calc(100vh - #{$footer-height});
    @media (min-width: 1682px) {
        padding-top: $desktop-navbar-height;
    }
    @media (max-width: 1681px) {
        padding-top: 213px;
    }
    @media (max-width: 1218px) {
        padding-top: 236px;
    }
    @media (max-width: 1111px) {
        padding-top: $mobile-navbar-height;
    }

    &.general-wrapper {
        @media (min-width: 1682px) {
            padding-top: $desktop-navbar-height;
        }
        @media (max-width: 1681px) {
            padding-top: 213px;
        }
        @media (max-width: 1218px) {
            padding-top: 236px;
        }
        @media (max-width: 1111px) {
            padding-top: $mobile-navbar-height;
        }
        background-color: rgb(244, 244, 244, 0.2);
    }
}

.full-height {
    min-height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

/*rtl:raw:
    .sub-nav img {
        transform: rotateY(180deg);
    }
*/

p {
    color: $grey-70;
}

li {
    color: $grey-70;
}

.top-bar-section {
    position: absolute;
    background-color: $grey-f1;
    top: 0;
    height: 237px;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    &:after {
        content: "";
        height: 237px;
        position: absolute;
        top: 0;
        width: 100%;
        overflow: hidden;
        background-image: url("../img/top-bar-design-svg.svg");
        background-position: center;
        background-size: cover;
        filter: invert(1);
        background-repeat: no-repeat;
        transform: scale(1.2, 1) rotateX(180deg);
        /*rtl:raw:
            transform: scale(-1.2,1) rotateX(180deg);
        */
        opacity: 0.06;
    }
}
