#site-footer {
    .footer-up {
        background-color: #004050;
        z-index: 1;

        &::before {
            content: "";
            width: 864px;
            height: 864px;
            position: absolute;
            top: -30%;
            left: -15%;
            @media (max-width: 1111px) {
                top: -5%;
                left: -50%;
            }
            background-image: url("../img/footer-bg-design.svg");
            background-repeat: no-repeat;
            background-position: top right;
            background-size: contain;
            z-index: -2;
        }

        &::after {
            content: "";
            width: 864px;
            height: 864px;
            position: absolute;
            bottom: -100%;
            right: -15%;
            @media (max-width: 1111px) {
                bottom: -5%;
                right: -50%;
            }
            background-image: url("../img/footer-bg-design.svg");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: contain;
            z-index: -2;
        }

        i {
            color: $accent;
        }

        .footer-nav-link {
            font-weight: $font-weight-normal;
            color: $footer-color;
            font-size: 1rem;
        }

        a.footer-nav-link:hover {
            color: $accent;
        }

        .footer-up_secondary {
            .footer-menu-h {
                h4 {
                    font-weight: $font-weight-semi-bold;
                    font-size: 1rem;
                    color: $footer-color;
                }
            }

            .footer-menu-h:after {
                content: "";
                display: inline-block;
                vertical-align: center;
                width: 50px;
                margin: 7px 0 20px 0;
                border-top: 3px solid $accent;
                border-radius: 25px;
            }

            .nav-link {
                padding: 10px 0 25px 0;
            }

            .nav-link:hover {
                opacity: 1;
            }

            i {
                @include media-breakpoint-up(lg) {
                    padding-right: 20px;
                }
            }

            .d-flex {
                padding: 10px 0;
            }

            .col-md-3 {
                div {
                    padding: 10px 0;
                }
            }
        }
    }

    .footer-down {
        p {
            color: $white;
        }
    }

    padding: 0;
    font-size: 1rem;

    .nav-link {
        font-size: 1rem;
        color: #FFF;
        font-weight: $font-weight-bold;
    }

    color: $grey-aa;


    a {
        color: $body-color;

        &:hover {
            color: $grey-aa;
        }
    }

    .fa, .fab, .fad, .fal, .far, .fas {
        font-size: 1.25rem;
        line-height: unset;
    }

    .social-icons {
        i {
            font-weight: $font-weight-normal;
            align-self: center;
            color: $white;
            transition: all 0.3s;

            &:hover {
                color: $accent;
            }
        }
    }

    img {
        height: 4rem;
    }
}

.members-logo-1:before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    background-image: url("../img/member1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 25px;
    vertical-align: middle;
    margin-right: 5px;
}

.members-logo-2:before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    background-image: url("../img/member2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 25px;
    vertical-align: middle;
    margin-right: 5px;
}

.members-logo-3:before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    background-image: url("../img/member3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 25px;
    vertical-align: middle;
    margin-right: 5px;
}

/*rtl:raw:
#site-footer .footer-up::after {
    transform:rotateY(180deg);
}
*/
