/*rtl:ignore*/
.searchbar-non-collapse {
    width: 450px;
    @include media-breakpoint-down(lg) {
        width: 350px;
    }
    right: 0;

    .input-group-addon {
        i {
            color: $primary;
        }
    }

    .search-web-form {
        input {
            &::-webkit-input-placeholder {
                color: #797979;
            }
        }
    }
}

/*rtl:ignore*/
.searchbar-collapse {
    width: 50px;
    left: 0;

    .input-group-addon {
        i {
            color: $grey-70;
        }
    }

    .search-web-form {
        input {
            &::-webkit-input-placeholder {
                color: transparent;
            }
        }
    }
}

/*rtl:ignore*/
#navbar-search-2 {
    height: 50px;
}

/*rtl:ignore*/
.searchbar {
    top: calc(50% - (50px / 2));
    height: 50px;
    background-color: $footer-bg;
    transition: all .3s;
    border-radius: 25px;
    z-index: -1;

    .input-group-addon {
        padding-top: 14px;
        z-index: 100;

        i {
            transition: color 0.3s;
        }
    }

    .search-web-form {
        input {
            padding-left: 15px !important;

            &::-webkit-input-placeholder {
                /*rtl:raw:
                padding-right: 5px !important;
                */
                transition: color 0.3s;
            }
        }
    }
}

/*rtl:ignore*/
.searchbar-web {
    top: calc(50% - (50px / 2));
    height: 50px;
    background-color: $footer-bg;
    transition: all .3s;
    border-radius: 10px;
    z-index: -1;

    .input-group-addon {
        padding-top: 14px;
        z-index: 100;

        i {
            transition: color 0.3s;
        }
    }

    .search-web-form {
        input {
            padding-left: 15px !important;

            &::-webkit-input-placeholder {
                transition: color 0.3s;
            }
        }
    }
}

/*rtl:ignore*/
.form-group {
    display: flex;
    flex-direction: column;
    justify-content: left;

    label {
        margin-left: 12px;
    }

    .search-filter {
        height: 50px;
        background-color: $footer-bg;
        border-radius: 10px;
        padding-left: 15px !important;
        outline: none;

        &::-webkit-input-placeholder {
            color: #797979;
        }
    }
}

/*rtl:ignore*/
.searchbar-filter {
    display: flex;
    flex-direction: row;

    .filter-options {
        margin-bottom: 1.5rem;
        margin-top: 33px;
    }

    label {
        font-weight: 600;
        margin-bottom: 9px;
    }
}

/*rtl:ignore*/
.searchbar .search-web-form {
    button {
        background-color: $footer-bg;
        height: 55px
    }

    input {
        outline: none;
        background-color: $footer-bg;
    }
}

/*rtl:ignore*/
.searchbar-web .search-web-form {
    button {
        background-color: $footer-bg;
        height: 55px
    }

    input {
        outline: none;
        background-color: $footer-bg;
    }
}

/*rtl:ignore*/
.search-web-button {
    border-radius: 25px;
    z-index: 101;

    &.active {
        border-radius: 0 25px 25px 0;
    }
}
