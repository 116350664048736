.page-blocks {
    line-height: 24px;
    color: $black;

    // Done
    .paragraph-block {
        text-align: justify;
    }

    // Done
    .quote-block {
        .blockquote-text {
            line-height: var(--bs-body-line-height);
        }
    }

    // Done
    .image-text-block {
        h5.section-title {
            color: $grey-4e;
            font-size: 1rem;
            font-weight: $font-weight-semi-bold;
        }

        .img-fluid {
            width: 390px;
        }
    }


    $h-map: (
        h1: 2.5rem,
        h2: 2.375rem,
        h3: 2.25rem,
        h4: 2.125rem,
        h5: 2rem,
        h6: 1.875rem,
    );

    @each $weight, $value in $h-map {
        #{$weight} {
            font-size: $value;
        }
    }

    .image-divider-block {
        .primary-transparent {
            background-color: rgba($primary-color, 0.67);

            .section-title {
                opacity: 1 !important;
            }

            .section-subtext {
                opacity: 1 !important;
            }
        }
    }

    /* // Done
     .action-card-block {
         .content-row {
             min-height: 300px;
         }

         img {
             height: 100%;
         }

         a {
             text-decoration: none;
         }

         .btn {
             border-color: $grey-c5;
             color: $grey-c5;
             font-weight: $font-weight-bold;

             &:hover {
                 background-color: $grey-4e;
                 color: $white;
             }
         }
     }*/

    // Done
    // .block-list in typography.scss

    // Done

}

/*!rtl:raw:
.page-blocks {
    word-spacing: 5px;
}
*/

/*Page Divider*/
.page-divider {
    position: relative;
}

.page-divider-child {
    height: 250px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
        z-index: 1;
        color: #FFF
    }

    h5 {
        z-index: 1;
        color: #FFF
    }
}

.page-divider-child:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: #2A2A2A linear-gradient(-90deg, #2A2A2A 0%, #2A2A2A 100%);
    opacity: 0.9;
    top: 0;
}

/*news Box*/
.action-card-box {
    min-height: 300px;

    .card-body {
        padding: 2.5rem 2rem;
    }

    div {
        overflow: hidden;
    }

    @include media-breakpoint-down(lg) {
        img {
            width: 100%;
        }
    }
    @include media-breakpoint-up(lg) {
        img {
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    i {
        transition: all 0.3s;
    }

    p {
        line-height: 31px;
    }

    .action-card-read-more:hover {
        //color: $primary !important;
        margin-bottom: 3rem;

        i {
            transform: translate(5px, 0);
            transition: all 0.3s;
        }
    }
}

/*quote box*/
.quote-box {
    /*background-image: url("../img/quote-gray.svg");
    background-repeat: no-repeat;
    background-size: 200px;
    background-position-x: 99%;
    background-position-y: 97%;*/
    background-color: transparent;
    position: relative;

    .quote-symbol {
        position: absolute;
        top: -100px;
        left: -15px;
    }

    @include media-breakpoint-up(lg) {
        .card-body {
            overflow: hidden;

            &::before {
                content: "";
                width: 145px;
                height: 95px;
                position: absolute;
                bottom: 0;
                right: -2px;
                background-image: url("../images/quote-bg.svg");
                background-repeat: no-repeat;
                background-position: top right;
                background-size: contain;
            }

            &:after {
                content: "";
                height: 165px;
                position: absolute;
                bottom: 15px;
                right: 15px;
                width: 245px;
                overflow: hidden;
                background-image: url("../img/quote.svg");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                transform: rotate(180deg);
                z-index: -1;
            }
        }
    }
}

/*video embed*/
.video-embed {
    button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
    }

    button:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation-name: btnanim;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-color: $primary;
        border-radius: 50%;
        z-index: -1;
        width: 50px;
        height: 50px;
    }

    @keyframes btnanim {
        0% {
            opacity: 1;
            width: 50px;
            height: 50px;
        }
        50% {
            opacity: 0.5;
            width: 70px;
            height: 70px;
        }
        100% {
            opacity: 0;
            width: 80px;
            height: 80px;
        }
    }
}

/*table Blocks*/
.table {
    :not(:first-child) {
        border-top: 0;
    }

    th {
        border: none;
    }

    tr {
        vertical-align: top;

        &:last-child {
            td {
                border: none;
            }
        }
    }
}

/*related content*/
.related-content {
    .card {
        overflow: hidden;

        &:hover {
            .hover-effect > i {
                bottom: 15px;
                transition: bottom 0.5s;
            }
        }
    }

    .d-flex {
        i {
            font-size: 3.25rem !important;
        }

        .hover-effect {
            i {
                font-size: 7rem !important;
                right: 20px;
                bottom: -150px;
                opacity: 0.5;
                transition: bottom 0.5s;
                /*rtl:ignore*/
                transform: rotate(-20deg);
            }
        }
    }
}

/*side menu*/
.side-menu {
    a {
        padding: 15px 0;
        font-size: 1rem;
        color: $grey-aa;
    }

    .nav-item {
        border-bottom: 1px solid $grey-e6;
    }

    .nav-item:last-of-type {
        border-bottom: 0px solid $grey-e6;
    }

    .active {
        color: $secondary;
    }
}
