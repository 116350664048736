.post {
    p {
        color: $grey-70;
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: all 0.3s;
    }

    h6 {
        color: $grey-c5;
    }

    /*i:first-child {
        padding: 1.563rem 1.875rem;
        font-size: 40px;
        border-radius: 50px;
        background-color: rgb($primary, 0.05);
        color: $primary;
        transition: all 0.3s;
    }*/

    .post-item-icon {
        background-color: rgb(#8C8C8C, 0.05);
        color: $primary;
        transition: all 0.3s;
        width: 90px;

        i {
            font-size: 40px;
            color: #8C8C8C;
            transition: all 0.3s;
        }
    }

    .card-body {
        height: 135px;
    }

    &:hover {
        .post-item-icon {
            background-color: rgb($primary, 0.15);
            transition: all 0.3s;

            i {
                color: $primary;
                transition: all 0.3s;
            }
        }

        p {
            color: rgb($primary, 1);
            transition: all 0.3s;
        }

        h4 {
            color: rgb($primary, 1);
            transition: all 0.3s;
        }
    }
}

.publication {
    p {
        color: $grey-70;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: all 0.3s;
    }

    h6 {
        color: $grey-c5;
    }

    .card {
        margin-top: -38px;
        z-index: -1;
    }

    .card-body {
        height: 125px;
    }

    img {
        box-shadow: 0 19px 23px 0 rgba(0, 0, 0, 0.16);
        background-color: $white;
    }

    &:hover {
        .publication-title {
            color: rgb($primary, 1);
            transition: all 0.3s;
        }
    }

}

.post-title {
    i {
        padding: 1.563rem 1.875rem;
        font-size: 45px;
        border-radius: 50px;
        background-color: rgb($primary, 0.05);
        color: $primary;
        transition: all 0.3s;
    }

    .publication-thumb {
        box-shadow: 0 19px 23px 0 rgba(0, 0, 0, 0.16);
    }
}

.link-hover-primary {
    color: $grey-c5;

    &:hover {
        color: $primary;
    }
}

.btn-attachments {
    padding: 0.5rem 1rem;
}

.new-post {
    right: 10px;
    z-index: 1;
    top: -15px;
    background-color: #00DFD8;
    color: $white;
}

.updated-post {
    right: 10px;
    z-index: 1;
    top: -15px;
    background-color: $accent;
    color: $white;
}

.post-filter {
    input {
        outline: none;
    }
}

@include media-breakpoint-up(lg) {
    .collapse.dont-collapse-lg {
        display: block;
        height: auto !important;
        visibility: visible;
    }
    .post:first-of-type {
        margin-top: 0;
    }
    .publication:first-of-type {
        margin-top: 0;
    }
}
