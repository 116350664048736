$h4-underline-line-thickness: 2.5px;
$h4-underline-bottom: -.825rem;
$h4-underline-width: 3rem;
$h4-underline-border-radius: 10px;

/**
Utilities
 */

// Post type card status thing
@mixin status-box($color) {
    color: $color;
    background-color: tint-color($color, 85%);
}

.status-box {
    height: 1.875rem;
    right: .625rem;
    top: -15px;
}

// Standard Boostrap looping
@each $color, $value in $status-box-colors {
    .status-box-#{$color} {
        @include status-box($value);
    }
}

// Exactly Bootstrap's link color generator, but REQUIRED hover and focus.
@each $color, $value in $grey-map {
    .link-#{$color} {
        color: $value;

        &:hover,
        &:focus {
            color: lighten($primary-color, .2);
        }
    }
}


.title-underscore {
    position: relative;
    font-size: 1.5rem;

    &::before {
        position: absolute;
        content: '';
        display: inline-block;
        width: $h4-underline-width;
        height: $h4-underline-line-thickness;
        bottom: $h4-underline-bottom;
        background-color: $primary-color;
        border-radius: $h4-underline-border-radius;
    }

    &::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 1rem;
        height: $h4-underline-line-thickness;
        left: calc(#{$h4-underline-width} + .6rem);
        bottom: $h4-underline-bottom;
        background-color: $primary-color;
        border-radius: $h4-underline-border-radius;
    }
}

.title-underscore-text-center {
    &::before {
        left: 47%;
    }

    &::after {
        left: 52%;
    }
}

.title-underscore-white {
    &::before {
        background-color: $white;
    }

    &::after {
        background-color: $white;
    }
}

.title-related-line-above {
    &:before {
        width: 46px;
        border-bottom: solid 3px $primary;
        position: absolute;
        left: -2px;
        top: -8px;
        content: "";
        border-radius: 10px;
    }
}
