/**
Projects
 */

.project {
    .image {
        position: relative;
        width: auto;

        .card-img-top {
            max-height: 200px;
            min-height: 150px;
            object-fit: cover;
        }

        span {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
        }
    }

    a {
        color: $black;
        text-decoration: none;

        &:hover {
            color: $primary-color
        }
    }

    .fa-stack {
        .fa-circle {
            color: #F5F5F5;
        }

        .fa-stack-1x {
            color: $light-grey;
        }
    }

    .circle {
        .circles-text {
            font-size: 15px !important;
            font-weight: $font-weight-bold;
        }
    }

    .attributes {
        color: $min-grey;
        font-size: 13px;
    }
}

.project-show {
    .attr-title {
        color: $min-grey;
    }

    .attr-value {
        color: $body-color;
        font-size: 16px;
    }

    .circle {
        .circles-text {
            font-size: 15px !important;
            font-weight: $font-weight-bold;
        }
    }

    .sticky-top {
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
}
