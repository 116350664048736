.custom-pagination {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .pagination::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .pagination {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.pagination * {
    //border-radius: 5px !important;
}

.page-item {
    transition: all 0.15s ease-in-out;

    .page-link {
        background-color: $grey-f6;

        &.border {
            background-color: transparent;
            border: 1px solid $accent !important;

            i {
                color: $accent;
            }

            &:hover {
                background-color: $accent;

                i {
                    color: #fff;
                }
            }
        }
    }

    &.disabled {
        .page-link {
            transition: all 0.15s ease-in-out;
            color: $grey-c5;
            background-color: transparent;
        }
    }

    &.active {
        .page-link {
            background-color: $grey-f6;
            color: $accent;
            transition: all 0.15s ease-in-out;
        }
    }

    &:not(.disabled):hover {
        .page-link {
            color: $accent;
        }
    }
}

.page-link {
    border-radius: 5px !important;
}
