@import "~bootstrap-5/scss/variables";
@import "~bootstrap-5/scss/mixins";

// Responsive sticky top
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .sticky#{$infix}-top {
            position: sticky;
            @media (min-width: 1112px) {
                top: calc(#{$desktop-navbar-height} + 1.6rem);
            }
            @media (max-width: 1111px) {
                top: calc(#{$mobile-navbar-height} + 1.6rem);
            }
            z-index: $zindex-sticky;
        }
    }
}

@each $color, $value in $grey-map {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $grey-map {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}
