@charset "UTF-8";
/**
Mixins
 */
/**
 Global variables for both web and admin
 */
/*modal*/
/*carousel*/
/**
BS
 */
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #309AA7;
  --bs-accent: #FFAA00;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 48, 154, 167;
  --bs-accent-rgb: 255, 170, 0;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 250, 250, 250;
  --bs-font-sans-serif: Montserrat, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #FAFAFA;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #309AA7;
  text-decoration: underline;
}
a:hover {
  color: #267b86;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FAFAFA;
  border: 1px solid #dee2e6;
  border-radius: 0rem;
  box-shadow: 0 0.188rem 1.625rem rgba(0, 0, 0, 0.05);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 888px) {
  .container-md, .container-sm, .container {
    max-width: 840px;
  }
}
@media (min-width: 1112px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1080px;
  }
}
@media (min-width: 1320px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1580px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3.25rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3.25rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3.5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 4rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 4rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 3.25rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 3.25rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 888px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 3.25rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 3.25rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 4rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1112px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 3.25rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 3.25rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1320px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 3.25rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 3.25rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 4rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #000;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #000;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
  vertical-align: top;
  border-color: #E6E6E6;
}
.table > :not(caption) > * > * {
  padding: 1.25rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d6ebed;
  --bs-table-striped-bg: #cbdfe1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d4d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6d9db;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d4d5;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 887.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1111.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1319.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: #000;
}

.col-form-label {
  padding-top: 0.725rem;
  padding-bottom: 0.725rem;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
}

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.725rem 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #fff;
  appearance: none;
  border-radius: 0;
  box-shadow: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #E6E6E6;
  outline: 0;
  box-shadow: 0, 0;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #E6E6E6;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.725rem 1.25rem;
  margin: -0.725rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.725rem 1.25rem;
  margin: -0.725rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.725rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.625rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.45rem);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.725rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.725rem 3.75rem 0.725rem 1.25rem;
  -moz-padding-start: calc(1.25rem - 3px);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23C5C5C5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 16px 12px;
  border: 0 solid #fff;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #E6E6E6;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.25rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.625rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #E6E6E6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
}
.form-check-input:checked {
  background-color: #309AA7;
  border-color: #309AA7;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #309AA7;
  border-color: #309AA7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23E6E6E6'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FAFAFA, 0;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FAFAFA, 0;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #309AA7;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c1e1e5;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #309AA7;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c1e1e5;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.5rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.25rem;
  pointer-events: none;
  border: 0 solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1.25rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.725rem 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #fff;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.625rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 1.45rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3625rem) center;
  background-size: calc(0.75em + 0.725rem) calc(0.75em + 0.725rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.45rem);
  background-position: top calc(0.375em + 0.3625rem) right calc(0.375em + 0.3625rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 6.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23C5C5C5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.75em + 0.725rem) calc(0.75em + 0.725rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1.45rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3625rem) center;
  background-size: calc(0.75em + 0.725rem) calc(0.75em + 0.725rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.45rem);
  background-position: top calc(0.375em + 0.3625rem) right calc(0.375em + 0.3625rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 6.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23C5C5C5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.75em + 0.725rem) calc(0.75em + 0.725rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.488rem 1.425rem;
  font-size: 1rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #000;
  background-color: #309AA7;
  border-color: #309AA7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #000;
  background-color: #4fa9b4;
  border-color: #45a4b0;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #4fa9b4;
  border-color: #45a4b0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(41, 131, 142, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #59aeb9;
  border-color: #45a4b0;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(41, 131, 142, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #309AA7;
  border-color: #309AA7;
}

.btn-accent {
  color: #000;
  background-color: #FFAA00;
  border-color: #FFAA00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-accent:hover {
  color: #000;
  background-color: #ffb726;
  border-color: #ffb31a;
}
.btn-check:focus + .btn-accent, .btn-accent:focus {
  color: #000;
  background-color: #ffb726;
  border-color: #ffb31a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 145, 0, 0.5);
}
.btn-check:checked + .btn-accent, .btn-check:active + .btn-accent, .btn-accent:active, .btn-accent.active, .show > .btn-accent.dropdown-toggle {
  color: #000;
  background-color: #ffbb33;
  border-color: #ffb31a;
}
.btn-check:checked + .btn-accent:focus, .btn-check:active + .btn-accent:focus, .btn-accent:active:focus, .btn-accent.active:focus, .show > .btn-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 145, 0, 0.5);
}
.btn-accent:disabled, .btn-accent.disabled {
  color: #000;
  background-color: #FFAA00;
  border-color: #FFAA00;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #309AA7;
  border-color: #309AA7;
}
.btn-outline-primary:hover {
  color: #000;
  background-color: #309AA7;
  border-color: #309AA7;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #309AA7;
  border-color: #309AA7;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(48, 154, 167, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #309AA7;
  background-color: transparent;
}

.btn-outline-accent {
  color: #FFAA00;
  border-color: #FFAA00;
}
.btn-outline-accent:hover {
  color: #000;
  background-color: #FFAA00;
  border-color: #FFAA00;
}
.btn-check:focus + .btn-outline-accent, .btn-outline-accent:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 170, 0, 0.5);
}
.btn-check:checked + .btn-outline-accent, .btn-check:active + .btn-outline-accent, .btn-outline-accent:active, .btn-outline-accent.active, .btn-outline-accent.dropdown-toggle.show {
  color: #000;
  background-color: #FFAA00;
  border-color: #FFAA00;
}
.btn-check:checked + .btn-outline-accent:focus, .btn-check:active + .btn-outline-accent:focus, .btn-outline-accent:active:focus, .btn-outline-accent.active:focus, .btn-outline-accent.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 170, 0, 0.5);
}
.btn-outline-accent:disabled, .btn-outline-accent.disabled {
  color: #FFAA00;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #309AA7;
  text-decoration: underline;
}
.btn-link:hover {
  color: #267b86;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0rem;
  box-shadow: 0 6px 1.25rem rgba(0, 0, 0, 0.16);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 888px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1112px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1320px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid transparent;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #309AA7;
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: #309AA7;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.2rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1.2rem;
  color: #000;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: transparent;
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #309AA7;
  background-color: transparent;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: transparent;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.06875rem;
  padding-left: 1.06875rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.9rem 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #309AA7;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #FAFAFA;
  border-color: #dee2e6 #dee2e6 #FAFAFA;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #309AA7;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-left: 1rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.7125rem;
  padding-bottom: 0.7125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.75rem 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 888px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1112px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1320px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.4rem;
  padding-left: 1.4rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: #309AA7;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #309AA7;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #309AA7;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #309AA7;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 2rem 2rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 2rem;
}

.card-header {
  padding: 1rem 2rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #FAFAFA;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #2b8b96;
  background-color: #eaf5f6;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232b8b96'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #E6E6E6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #C5C5C5;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #309AA7;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #C5C5C5;
  text-decoration: none;
  background-color: transparent;
  border: 0px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #309AA7;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: none;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: none;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0.625rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #309AA7;
  border-color: #309AA7;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #E6E6E6;
}

.page-link {
  padding: 0.375rem 1rem;
}

.page-item .page-link {
  border-radius: 0rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item .page-link {
  border-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item .page-link {
  border-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #1d5c64;
  background-color: #d6ebed;
  border-color: #c1e1e5;
}
.alert-primary .alert-link {
  color: #174a50;
}

.alert-accent {
  color: #664400;
  background-color: #ffeecc;
  border-color: #ffe6b3;
}
.alert-accent .alert-link {
  color: #523600;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #309AA7;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #309AA7;
  border-color: #309AA7;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 888px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1112px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1320px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1d5c64;
  background-color: #d6ebed;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1d5c64;
  background-color: #c1d4d5;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1d5c64;
  border-color: #1d5c64;
}

.list-group-item-accent {
  color: #664400;
  background-color: #ffeecc;
}
.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: #664400;
  background-color: #e6d6b8;
}
.list-group-item-accent.list-group-item-action.active {
  color: #fff;
  background-color: #664400;
  border-color: #664400;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: 0 0.188rem 1.625rem rgba(0, 0, 0, 0.05);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1112px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1320px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 887.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1111.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1319.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #fff;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.188rem 1.625rem rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #309AA7;
}
.link-primary:hover, .link-primary:focus {
  color: #59aeb9;
}

.link-accent {
  color: #FFAA00;
}
.link-accent:hover, .link-accent:focus {
  color: #ffbb33;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.ratio-6x4 {
  --bs-aspect-ratio: calc(6 / 4 * 100%);
}

.ratio-4x6 {
  --bs-aspect-ratio: calc(4 / 6 * 100%);
}

.ratio-document-embed {
  --bs-aspect-ratio: calc(13 / 9 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 888px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1112px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1320px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.188rem 1.625rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #309AA7 !important;
}

.border-accent {
  border-color: #FFAA00 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.25rem !important;
}

.gap-7 {
  gap: 3.5rem !important;
}

.gap-8 {
  gap: 4rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 3.25rem !important;
}

.m-7 {
  margin: 3.5rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-7 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-8 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 3.25rem !important;
}

.mt-7 {
  margin-top: 3.5rem !important;
}

.mt-8 {
  margin-top: 4rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 3.25rem !important;
}

.me-7 {
  margin-right: 3.5rem !important;
}

.me-8 {
  margin-right: 4rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.25rem !important;
}

.mb-7 {
  margin-bottom: 3.5rem !important;
}

.mb-8 {
  margin-bottom: 4rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 3.25rem !important;
}

.ms-7 {
  margin-left: 3.5rem !important;
}

.ms-8 {
  margin-left: 4rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 3.25rem !important;
}

.p-7 {
  padding: 3.5rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-7 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-8 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 3.25rem !important;
}

.pt-7 {
  padding-top: 3.5rem !important;
}

.pt-8 {
  padding-top: 4rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 3.25rem !important;
}

.pe-7 {
  padding-right: 3.5rem !important;
}

.pe-8 {
  padding-right: 4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 3.25rem !important;
}

.pb-7 {
  padding-bottom: 3.5rem !important;
}

.pb-8 {
  padding-bottom: 4rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 3.25rem !important;
}

.ps-7 {
  padding-left: 3.5rem !important;
}

.ps-8 {
  padding-left: 4rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-accent {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-grey-2a {
  --bs-text-opacity: 1;
  color: #000 !important;
}

.text-grey-4e {
  --bs-text-opacity: 1;
  color: #000 !important;
}

.text-grey-70 {
  --bs-text-opacity: 1;
  color: #000 !important;
}

.text-grey-aa {
  --bs-text-opacity: 1;
  color: #000 !important;
}

.text-grey-c5 {
  --bs-text-opacity: 1;
  color: #C5C5C5 !important;
}

.text-grey-e6 {
  --bs-text-opacity: 1;
  color: #E6E6E6 !important;
}

.text-grey-ec {
  --bs-text-opacity: 1;
  color: #ECECEC !important;
}

.text-grey-f1 {
  --bs-text-opacity: 1;
  color: #F1F1F1 !important;
}

.text-grey-f6 {
  --bs-text-opacity: 1;
  color: #F6F6F6 !important;
}

.text-grey-fa {
  --bs-text-opacity: 1;
  color: #FAFAFA !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-grey-2a {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}

.bg-grey-4e {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}

.bg-grey-70 {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}

.bg-grey-aa {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}

.bg-grey-c5 {
  --bs-bg-opacity: 1;
  background-color: #C5C5C5 !important;
}

.bg-grey-e6 {
  --bs-bg-opacity: 1;
  background-color: #E6E6E6 !important;
}

.bg-grey-ec {
  --bs-bg-opacity: 1;
  background-color: #ECECEC !important;
}

.bg-grey-f1 {
  --bs-bg-opacity: 1;
  background-color: #F1F1F1 !important;
}

.bg-grey-f6 {
  --bs-bg-opacity: 1;
  background-color: #F6F6F6 !important;
}

.bg-grey-fa {
  --bs-bg-opacity: 1;
  background-color: #FAFAFA !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0rem !important;
}

.rounded-2 {
  border-radius: 0rem !important;
}

.rounded-3 {
  border-radius: 0rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 0rem !important;
}

.rounded-top {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.rounded-end {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 3.25rem !important;
  }

  .gap-sm-7 {
    gap: 3.5rem !important;
  }

  .gap-sm-8 {
    gap: 4rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 3.25rem !important;
  }

  .m-sm-7 {
    margin: 3.5rem !important;
  }

  .m-sm-8 {
    margin: 4rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-7 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-8 {
    margin-top: 4rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 3.25rem !important;
  }

  .me-sm-7 {
    margin-right: 3.5rem !important;
  }

  .me-sm-8 {
    margin-right: 4rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-7 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-8 {
    margin-left: 4rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 3.25rem !important;
  }

  .p-sm-7 {
    padding: 3.5rem !important;
  }

  .p-sm-8 {
    padding: 4rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-7 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-8 {
    padding-top: 4rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-7 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-8 {
    padding-right: 4rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-7 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-8 {
    padding-left: 4rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 888px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 3.25rem !important;
  }

  .gap-md-7 {
    gap: 3.5rem !important;
  }

  .gap-md-8 {
    gap: 4rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 3.25rem !important;
  }

  .m-md-7 {
    margin: 3.5rem !important;
  }

  .m-md-8 {
    margin: 4rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 3.25rem !important;
  }

  .mt-md-7 {
    margin-top: 3.5rem !important;
  }

  .mt-md-8 {
    margin-top: 4rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 3.25rem !important;
  }

  .me-md-7 {
    margin-right: 3.5rem !important;
  }

  .me-md-8 {
    margin-right: 4rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 3.25rem !important;
  }

  .ms-md-7 {
    margin-left: 3.5rem !important;
  }

  .ms-md-8 {
    margin-left: 4rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 3.25rem !important;
  }

  .p-md-7 {
    padding: 3.5rem !important;
  }

  .p-md-8 {
    padding: 4rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 3.25rem !important;
  }

  .pt-md-7 {
    padding-top: 3.5rem !important;
  }

  .pt-md-8 {
    padding-top: 4rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 3.25rem !important;
  }

  .pe-md-7 {
    padding-right: 3.5rem !important;
  }

  .pe-md-8 {
    padding-right: 4rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 3.25rem !important;
  }

  .ps-md-7 {
    padding-left: 3.5rem !important;
  }

  .ps-md-8 {
    padding-left: 4rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1112px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 3.25rem !important;
  }

  .gap-lg-7 {
    gap: 3.5rem !important;
  }

  .gap-lg-8 {
    gap: 4rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 3.25rem !important;
  }

  .m-lg-7 {
    margin: 3.5rem !important;
  }

  .m-lg-8 {
    margin: 4rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-7 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-8 {
    margin-top: 4rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 3.25rem !important;
  }

  .me-lg-7 {
    margin-right: 3.5rem !important;
  }

  .me-lg-8 {
    margin-right: 4rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-7 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-8 {
    margin-left: 4rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 3.25rem !important;
  }

  .p-lg-7 {
    padding: 3.5rem !important;
  }

  .p-lg-8 {
    padding: 4rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-7 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 4rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-7 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-8 {
    padding-right: 4rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-7 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-8 {
    padding-left: 4rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1320px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 3.25rem !important;
  }

  .gap-xl-7 {
    gap: 3.5rem !important;
  }

  .gap-xl-8 {
    gap: 4rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 3.25rem !important;
  }

  .m-xl-7 {
    margin: 3.5rem !important;
  }

  .m-xl-8 {
    margin: 4rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-7 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-7 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-8 {
    margin-top: 4rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 3.25rem !important;
  }

  .me-xl-7 {
    margin-right: 3.5rem !important;
  }

  .me-xl-8 {
    margin-right: 4rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-7 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-8 {
    margin-left: 4rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 3.25rem !important;
  }

  .p-xl-7 {
    padding: 3.5rem !important;
  }

  .p-xl-8 {
    padding: 4rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-7 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-7 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-8 {
    padding-top: 4rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-7 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-8 {
    padding-right: 4rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 4rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-7 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-8 {
    padding-left: 4rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.sticky-top {
  position: sticky;
  z-index: 1020;
}
@media (min-width: 1112px) {
  .sticky-top {
    top: calc(191px + 1.6rem);
  }
}
@media (max-width: 1111px) {
  .sticky-top {
    top: calc(81px + 1.6rem);
  }
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 576px) and (min-width: 1112px) {
  .sticky-sm-top {
    top: calc(191px + 1.6rem);
  }
}
@media (min-width: 576px) and (max-width: 1111px) {
  .sticky-sm-top {
    top: calc(81px + 1.6rem);
  }
}
@media (min-width: 888px) {
  .sticky-md-top {
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 888px) and (min-width: 1112px) {
  .sticky-md-top {
    top: calc(191px + 1.6rem);
  }
}
@media (min-width: 888px) and (max-width: 1111px) {
  .sticky-md-top {
    top: calc(81px + 1.6rem);
  }
}
@media (min-width: 1112px) {
  .sticky-lg-top {
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 1112px) and (min-width: 1112px) {
  .sticky-lg-top {
    top: calc(191px + 1.6rem);
  }
}
@media (min-width: 1112px) and (max-width: 1111px) {
  .sticky-lg-top {
    top: calc(81px + 1.6rem);
  }
}
@media (min-width: 1320px) {
  .sticky-xl-top {
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 1320px) and (min-width: 1112px) {
  .sticky-xl-top {
    top: calc(191px + 1.6rem);
  }
}
@media (min-width: 1320px) and (max-width: 1111px) {
  .sticky-xl-top {
    top: calc(81px + 1.6rem);
  }
}
.btn-grey-4e {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-4e:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-grey-4e, .btn-grey-4e:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-grey-4e, .btn-check:active + .btn-grey-4e, .btn-grey-4e:active, .btn-grey-4e.active, .show > .btn-grey-4e.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-grey-4e:focus, .btn-check:active + .btn-grey-4e:focus, .btn-grey-4e:active:focus, .btn-grey-4e.active:focus, .show > .btn-grey-4e.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-grey-4e:disabled, .btn-grey-4e.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-grey-70 {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-70:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-grey-70, .btn-grey-70:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-grey-70, .btn-check:active + .btn-grey-70, .btn-grey-70:active, .btn-grey-70.active, .show > .btn-grey-70.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-grey-70:focus, .btn-check:active + .btn-grey-70:focus, .btn-grey-70:active:focus, .btn-grey-70.active:focus, .show > .btn-grey-70.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-grey-70:disabled, .btn-grey-70.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-grey-aa {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-aa:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-grey-aa, .btn-grey-aa:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-grey-aa, .btn-check:active + .btn-grey-aa, .btn-grey-aa:active, .btn-grey-aa.active, .show > .btn-grey-aa.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-grey-aa:focus, .btn-check:active + .btn-grey-aa:focus, .btn-grey-aa:active:focus, .btn-grey-aa.active:focus, .show > .btn-grey-aa.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-grey-aa:disabled, .btn-grey-aa.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-grey-c5 {
  color: #000;
  background-color: #C5C5C5;
  border-color: #C5C5C5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-c5:hover {
  color: #000;
  background-color: #cecece;
  border-color: #cbcbcb;
}
.btn-check:focus + .btn-grey-c5, .btn-grey-c5:focus {
  color: #000;
  background-color: #cecece;
  border-color: #cbcbcb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(167, 167, 167, 0.5);
}
.btn-check:checked + .btn-grey-c5, .btn-check:active + .btn-grey-c5, .btn-grey-c5:active, .btn-grey-c5.active, .show > .btn-grey-c5.dropdown-toggle {
  color: #000;
  background-color: #d1d1d1;
  border-color: #cbcbcb;
}
.btn-check:checked + .btn-grey-c5:focus, .btn-check:active + .btn-grey-c5:focus, .btn-grey-c5:active:focus, .btn-grey-c5.active:focus, .show > .btn-grey-c5.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(167, 167, 167, 0.5);
}
.btn-grey-c5:disabled, .btn-grey-c5.disabled {
  color: #000;
  background-color: #C5C5C5;
  border-color: #C5C5C5;
}

.btn-grey-e6 {
  color: #000;
  background-color: #E6E6E6;
  border-color: #E6E6E6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-e6:hover {
  color: #000;
  background-color: #eaeaea;
  border-color: #e9e9e9;
}
.btn-check:focus + .btn-grey-e6, .btn-grey-e6:focus {
  color: #000;
  background-color: #eaeaea;
  border-color: #e9e9e9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(196, 196, 196, 0.5);
}
.btn-check:checked + .btn-grey-e6, .btn-check:active + .btn-grey-e6, .btn-grey-e6:active, .btn-grey-e6.active, .show > .btn-grey-e6.dropdown-toggle {
  color: #000;
  background-color: #ebebeb;
  border-color: #e9e9e9;
}
.btn-check:checked + .btn-grey-e6:focus, .btn-check:active + .btn-grey-e6:focus, .btn-grey-e6:active:focus, .btn-grey-e6.active:focus, .show > .btn-grey-e6.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(196, 196, 196, 0.5);
}
.btn-grey-e6:disabled, .btn-grey-e6.disabled {
  color: #000;
  background-color: #E6E6E6;
  border-color: #E6E6E6;
}

.btn-grey-ec {
  color: #000;
  background-color: #ECECEC;
  border-color: #ECECEC;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-ec:hover {
  color: #000;
  background-color: #efefef;
  border-color: #eeeeee;
}
.btn-check:focus + .btn-grey-ec, .btn-grey-ec:focus {
  color: #000;
  background-color: #efefef;
  border-color: #eeeeee;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(201, 201, 201, 0.5);
}
.btn-check:checked + .btn-grey-ec, .btn-check:active + .btn-grey-ec, .btn-grey-ec:active, .btn-grey-ec.active, .show > .btn-grey-ec.dropdown-toggle {
  color: #000;
  background-color: #f0f0f0;
  border-color: #eeeeee;
}
.btn-check:checked + .btn-grey-ec:focus, .btn-check:active + .btn-grey-ec:focus, .btn-grey-ec:active:focus, .btn-grey-ec.active:focus, .show > .btn-grey-ec.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(201, 201, 201, 0.5);
}
.btn-grey-ec:disabled, .btn-grey-ec.disabled {
  color: #000;
  background-color: #ECECEC;
  border-color: #ECECEC;
}

.btn-grey-f1 {
  color: #000;
  background-color: #F1F1F1;
  border-color: #F1F1F1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-f1:hover {
  color: #000;
  background-color: #f3f3f3;
  border-color: #f2f2f2;
}
.btn-check:focus + .btn-grey-f1, .btn-grey-f1:focus {
  color: #000;
  background-color: #f3f3f3;
  border-color: #f2f2f2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(205, 205, 205, 0.5);
}
.btn-check:checked + .btn-grey-f1, .btn-check:active + .btn-grey-f1, .btn-grey-f1:active, .btn-grey-f1.active, .show > .btn-grey-f1.dropdown-toggle {
  color: #000;
  background-color: #f4f4f4;
  border-color: #f2f2f2;
}
.btn-check:checked + .btn-grey-f1:focus, .btn-check:active + .btn-grey-f1:focus, .btn-grey-f1:active:focus, .btn-grey-f1.active:focus, .show > .btn-grey-f1.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(205, 205, 205, 0.5);
}
.btn-grey-f1:disabled, .btn-grey-f1.disabled {
  color: #000;
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}

.btn-grey-f6 {
  color: #000;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-f6:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-check:focus + .btn-grey-f6, .btn-grey-f6:focus {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(209, 209, 209, 0.5);
}
.btn-check:checked + .btn-grey-f6, .btn-check:active + .btn-grey-f6, .btn-grey-f6:active, .btn-grey-f6.active, .show > .btn-grey-f6.dropdown-toggle {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f7f7f7;
}
.btn-check:checked + .btn-grey-f6:focus, .btn-check:active + .btn-grey-f6:focus, .btn-grey-f6:active:focus, .btn-grey-f6.active:focus, .show > .btn-grey-f6.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(209, 209, 209, 0.5);
}
.btn-grey-f6:disabled, .btn-grey-f6.disabled {
  color: #000;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
}

.btn-grey-fa {
  color: #000;
  background-color: #FAFAFA;
  border-color: #FAFAFA;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-grey-fa:hover {
  color: #000;
  background-color: #fbfbfb;
  border-color: #fbfbfb;
}
.btn-check:focus + .btn-grey-fa, .btn-grey-fa:focus {
  color: #000;
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(213, 213, 213, 0.5);
}
.btn-check:checked + .btn-grey-fa, .btn-check:active + .btn-grey-fa, .btn-grey-fa:active, .btn-grey-fa.active, .show > .btn-grey-fa.dropdown-toggle {
  color: #000;
  background-color: #fbfbfb;
  border-color: #fbfbfb;
}
.btn-check:checked + .btn-grey-fa:focus, .btn-check:active + .btn-grey-fa:focus, .btn-grey-fa:active:focus, .btn-grey-fa.active:focus, .show > .btn-grey-fa.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(213, 213, 213, 0.5);
}
.btn-grey-fa:disabled, .btn-grey-fa.disabled {
  color: #000;
  background-color: #FAFAFA;
  border-color: #FAFAFA;
}

.btn-outline-grey-4e {
  color: #000;
  border-color: #000;
}
.btn-outline-grey-4e:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-grey-4e, .btn-outline-grey-4e:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-grey-4e, .btn-check:active + .btn-outline-grey-4e, .btn-outline-grey-4e:active, .btn-outline-grey-4e.active, .btn-outline-grey-4e.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-outline-grey-4e:focus, .btn-check:active + .btn-outline-grey-4e:focus, .btn-outline-grey-4e:active:focus, .btn-outline-grey-4e.active:focus, .btn-outline-grey-4e.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-grey-4e:disabled, .btn-outline-grey-4e.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-grey-70 {
  color: #000;
  border-color: #000;
}
.btn-outline-grey-70:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-grey-70, .btn-outline-grey-70:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-grey-70, .btn-check:active + .btn-outline-grey-70, .btn-outline-grey-70:active, .btn-outline-grey-70.active, .btn-outline-grey-70.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-outline-grey-70:focus, .btn-check:active + .btn-outline-grey-70:focus, .btn-outline-grey-70:active:focus, .btn-outline-grey-70.active:focus, .btn-outline-grey-70.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-grey-70:disabled, .btn-outline-grey-70.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-grey-aa {
  color: #000;
  border-color: #000;
}
.btn-outline-grey-aa:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-grey-aa, .btn-outline-grey-aa:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-grey-aa, .btn-check:active + .btn-outline-grey-aa, .btn-outline-grey-aa:active, .btn-outline-grey-aa.active, .btn-outline-grey-aa.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-outline-grey-aa:focus, .btn-check:active + .btn-outline-grey-aa:focus, .btn-outline-grey-aa:active:focus, .btn-outline-grey-aa.active:focus, .btn-outline-grey-aa.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-grey-aa:disabled, .btn-outline-grey-aa.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-grey-c5 {
  color: #C5C5C5;
  border-color: #C5C5C5;
}
.btn-outline-grey-c5:hover {
  color: #000;
  background-color: #C5C5C5;
  border-color: #C5C5C5;
}
.btn-check:focus + .btn-outline-grey-c5, .btn-outline-grey-c5:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 197, 197, 0.5);
}
.btn-check:checked + .btn-outline-grey-c5, .btn-check:active + .btn-outline-grey-c5, .btn-outline-grey-c5:active, .btn-outline-grey-c5.active, .btn-outline-grey-c5.dropdown-toggle.show {
  color: #000;
  background-color: #C5C5C5;
  border-color: #C5C5C5;
}
.btn-check:checked + .btn-outline-grey-c5:focus, .btn-check:active + .btn-outline-grey-c5:focus, .btn-outline-grey-c5:active:focus, .btn-outline-grey-c5.active:focus, .btn-outline-grey-c5.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(197, 197, 197, 0.5);
}
.btn-outline-grey-c5:disabled, .btn-outline-grey-c5.disabled {
  color: #C5C5C5;
  background-color: transparent;
}

.btn-outline-grey-e6 {
  color: #E6E6E6;
  border-color: #E6E6E6;
}
.btn-outline-grey-e6:hover {
  color: #000;
  background-color: #E6E6E6;
  border-color: #E6E6E6;
}
.btn-check:focus + .btn-outline-grey-e6, .btn-outline-grey-e6:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 230, 230, 0.5);
}
.btn-check:checked + .btn-outline-grey-e6, .btn-check:active + .btn-outline-grey-e6, .btn-outline-grey-e6:active, .btn-outline-grey-e6.active, .btn-outline-grey-e6.dropdown-toggle.show {
  color: #000;
  background-color: #E6E6E6;
  border-color: #E6E6E6;
}
.btn-check:checked + .btn-outline-grey-e6:focus, .btn-check:active + .btn-outline-grey-e6:focus, .btn-outline-grey-e6:active:focus, .btn-outline-grey-e6.active:focus, .btn-outline-grey-e6.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(230, 230, 230, 0.5);
}
.btn-outline-grey-e6:disabled, .btn-outline-grey-e6.disabled {
  color: #E6E6E6;
  background-color: transparent;
}

.btn-outline-grey-ec {
  color: #ECECEC;
  border-color: #ECECEC;
}
.btn-outline-grey-ec:hover {
  color: #000;
  background-color: #ECECEC;
  border-color: #ECECEC;
}
.btn-check:focus + .btn-outline-grey-ec, .btn-outline-grey-ec:focus {
  box-shadow: 0 0 0 0.25rem rgba(236, 236, 236, 0.5);
}
.btn-check:checked + .btn-outline-grey-ec, .btn-check:active + .btn-outline-grey-ec, .btn-outline-grey-ec:active, .btn-outline-grey-ec.active, .btn-outline-grey-ec.dropdown-toggle.show {
  color: #000;
  background-color: #ECECEC;
  border-color: #ECECEC;
}
.btn-check:checked + .btn-outline-grey-ec:focus, .btn-check:active + .btn-outline-grey-ec:focus, .btn-outline-grey-ec:active:focus, .btn-outline-grey-ec.active:focus, .btn-outline-grey-ec.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(236, 236, 236, 0.5);
}
.btn-outline-grey-ec:disabled, .btn-outline-grey-ec.disabled {
  color: #ECECEC;
  background-color: transparent;
}

.btn-outline-grey-f1 {
  color: #F1F1F1;
  border-color: #F1F1F1;
}
.btn-outline-grey-f1:hover {
  color: #000;
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}
.btn-check:focus + .btn-outline-grey-f1, .btn-outline-grey-f1:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 241, 241, 0.5);
}
.btn-check:checked + .btn-outline-grey-f1, .btn-check:active + .btn-outline-grey-f1, .btn-outline-grey-f1:active, .btn-outline-grey-f1.active, .btn-outline-grey-f1.dropdown-toggle.show {
  color: #000;
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}
.btn-check:checked + .btn-outline-grey-f1:focus, .btn-check:active + .btn-outline-grey-f1:focus, .btn-outline-grey-f1:active:focus, .btn-outline-grey-f1.active:focus, .btn-outline-grey-f1.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(241, 241, 241, 0.5);
}
.btn-outline-grey-f1:disabled, .btn-outline-grey-f1.disabled {
  color: #F1F1F1;
  background-color: transparent;
}

.btn-outline-grey-f6 {
  color: #F6F6F6;
  border-color: #F6F6F6;
}
.btn-outline-grey-f6:hover {
  color: #000;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
}
.btn-check:focus + .btn-outline-grey-f6, .btn-outline-grey-f6:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 246, 246, 0.5);
}
.btn-check:checked + .btn-outline-grey-f6, .btn-check:active + .btn-outline-grey-f6, .btn-outline-grey-f6:active, .btn-outline-grey-f6.active, .btn-outline-grey-f6.dropdown-toggle.show {
  color: #000;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
}
.btn-check:checked + .btn-outline-grey-f6:focus, .btn-check:active + .btn-outline-grey-f6:focus, .btn-outline-grey-f6:active:focus, .btn-outline-grey-f6.active:focus, .btn-outline-grey-f6.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(246, 246, 246, 0.5);
}
.btn-outline-grey-f6:disabled, .btn-outline-grey-f6.disabled {
  color: #F6F6F6;
  background-color: transparent;
}

.btn-outline-grey-fa {
  color: #FAFAFA;
  border-color: #FAFAFA;
}
.btn-outline-grey-fa:hover {
  color: #000;
  background-color: #FAFAFA;
  border-color: #FAFAFA;
}
.btn-check:focus + .btn-outline-grey-fa, .btn-outline-grey-fa:focus {
  box-shadow: 0 0 0 0.25rem rgba(250, 250, 250, 0.5);
}
.btn-check:checked + .btn-outline-grey-fa, .btn-check:active + .btn-outline-grey-fa, .btn-outline-grey-fa:active, .btn-outline-grey-fa.active, .btn-outline-grey-fa.dropdown-toggle.show {
  color: #000;
  background-color: #FAFAFA;
  border-color: #FAFAFA;
}
.btn-check:checked + .btn-outline-grey-fa:focus, .btn-check:active + .btn-outline-grey-fa:focus, .btn-outline-grey-fa:active:focus, .btn-outline-grey-fa.active:focus, .btn-outline-grey-fa.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(250, 250, 250, 0.5);
}
.btn-outline-grey-fa:disabled, .btn-outline-grey-fa.disabled {
  color: #FAFAFA;
  background-color: transparent;
}

/**
Layout
 */
#wrapper {
  min-height: calc(100vh - 6.875rem);
}
@media (min-width: 1682px) {
  #wrapper {
    padding-top: 191px;
  }
}
@media (max-width: 1681px) {
  #wrapper {
    padding-top: 213px;
  }
}
@media (max-width: 1218px) {
  #wrapper {
    padding-top: 236px;
  }
}
@media (max-width: 1111px) {
  #wrapper {
    padding-top: 81px;
  }
}
#wrapper.general-wrapper {
  background-color: rgba(244, 244, 244, 0.2);
}
@media (min-width: 1682px) {
  #wrapper.general-wrapper {
    padding-top: 191px;
  }
}
@media (max-width: 1681px) {
  #wrapper.general-wrapper {
    padding-top: 213px;
  }
}
@media (max-width: 1218px) {
  #wrapper.general-wrapper {
    padding-top: 236px;
  }
}
@media (max-width: 1111px) {
  #wrapper.general-wrapper {
    padding-top: 81px;
  }
}

.full-height {
  min-height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

/*rtl:raw:
    .sub-nav img {
        transform: rotateY(180deg);
    }
*/
p {
  color: #000;
}

li {
  color: #000;
}

.top-bar-section {
  position: absolute;
  background-color: #F1F1F1;
  top: 0;
  height: 237px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.top-bar-section:after {
  content: "";
  height: 237px;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-image: url("../img/top-bar-design-svg.svg");
  background-position: center;
  background-size: cover;
  filter: invert(1);
  background-repeat: no-repeat;
  transform: scale(1.2, 1) rotateX(180deg);
  /*rtl:raw:
      transform: scale(-1.2,1) rotateX(180deg);
  */
  opacity: 0.06;
}

/**
Typography
 */
.dv {
  font-family: Montserrat, sans-serif;
  /*!rtl:btn-primary btn-slide-accent*/
  direction: rtl;
}

.en {
  font-family: Montserrat, sans-serif;
  /*!rtl:ignore*/
  direction: ltr;
}

.dir-lrt {
  /*rtl:ignore*/
  direction: ltr;
}

.dir-rtl {
  /*rtl:ignore*/
  direction: rtl;
}

a {
  transition: all 0.5s ease;
}

.block-list {
  list-style: none;
  color: #000;
}
.block-list li:before {
  content: "";
  border: 4px solid #309AA7;
  border-radius: 50rem;
  width: 6px;
  margin-right: 8px;
  font-size: 17px;
  display: inline-block;
  margin-top: -5px;
  color: #FFAA00;
}
.block-list li:after {
  content: "";
  display: block;
  clear: both;
}

.underline-text:after {
  content: "";
  display: block;
  vertical-align: center;
  width: 75px;
  margin: 10px 0 0 0;
  border-top: 3.5px solid #FFAA00;
}

/* montserrat-200 - latin - Extra Light */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/montserrat/Montserrat-ExtraLight.otf") format("opentype");
  src: local(""), url("../fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-ExtraLight.woff") format("woff"), url("../fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat/Montserrat-Regular.otf") format("opentype");
  src: local(""), url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Regular.woff") format("woff"), url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* montserrat-500 - latin - Medium */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat/Montserrat-Medium.otf") format("opentype");
  src: local(""), url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Medium.woff") format("woff"), url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* montserrat-600 - latin - Semi Bold */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat/Montserrat-SemiBold.otf") format("opentype");
  src: local(""), url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff"), url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* montserrat-700 - latin - Bold */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat/Montserrat-Bold.otf") format("opentype");
  src: local(""), url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Bold.woff") format("woff"), url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* montserrat-900 - latin - Black */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/montserrat/Montserrat-Black.otf") format("opentype");
  src: local(""), url("../fonts/montserrat/Montserrat-Black.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Black.woff") format("woff"), url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* montserrat-400 - dhivehi */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/thaana/MV-Faruma.otf") format("opentype");
  unicode-range: U+0780-07BF;
}
/* montserrat-500 - dhivehi */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/thaana/MV-Waheed.otf") format("opentype");
  unicode-range: U+0780-07BF;
}
/* montserrat-600 - dhivehi */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/thaana/MV-Waheed.otf") format("opentype");
  unicode-range: U+0780-07BF;
}
/* montserrat-700 - dhivehi */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/thaana/MV-Bodu.otf") format("opentype");
  unicode-range: U+0780-07BF;
}
/* montserrat-900 - dhivehi */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/thaana/MV-Bodu.otf") format("opentype");
  unicode-range: U+0780-07BF;
}
@keyframes slideDown {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loadingBgAnimation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
/**
Navbar
 */
.search-icon {
  width: 1px;
}
.search-icon.fa-times {
  padding-left: 4px;
}
.search-icon.fa-search {
  position: absolute;
  top: 16px;
  right: 32px;
  color: #000;
  transition: all 0.3s;
}
.search-icon.fa-search.search-open {
  right: 332px;
  color: #FFAA00;
}

.flag-thumb {
  width: 1.25rem;
}

.nav-lang {
  font-size: 1rem;
  color: #C5C5C5;
}

.dropdown-menu {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideUp;
  border-radius: 10px;
  box-shadow: 0 0 15px -10px #888888;
  z-index: 10000;
  padding: 5px;
}
.dropdown-menu .dropdown-item {
  transition: all 0.5s ease;
  color: #000;
  margin-bottom: 5px;
  font-weight: 500;
  border-radius: 7px;
}
.dropdown-menu .dropdown-item.active {
  color: #fff;
  background-color: #309AA7;
}
.dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: #309AA7;
}

.dropdown .dropdown-toggle {
  font-size: 0.625rem;
  transition: all 0.5s ease;
}
.dropdown .dropdown-toggle.show {
  transform: rotate(180deg);
}
.dropdown i {
  font-size: 14px;
}
.dropdown.show .dropdown-toggle {
  transform: rotate(180deg);
}

.rounded-corners {
  border-radius: 15px !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

#main-navbar {
  padding-top: 0;
  background-color: #fff;
}
#main-navbar:not(.header-nav) .container-lg.py-2 {
  z-index: 1;
}
#main-navbar:not(.header-nav) .container-lg.py-2::before {
  content: "";
  width: 595px;
  height: 595px;
  position: absolute;
  top: -55%;
  left: 6%;
  background-image: url("../img/header-bg-design.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  z-index: -2;
}
@media (max-width: 1111px) {
  #main-navbar:not(.header-nav) .container-lg.py-2::before {
    width: 450px;
    height: 450px;
    top: 0;
    left: -15%;
  }
}
#main-navbar:not(.header-nav) .container-lg.py-2::after {
  content: "";
  width: 595px;
  height: 595px;
  position: absolute;
  bottom: -25%;
  right: 10%;
  background-image: url("../img/header-bg-design.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  z-index: -2;
}
@media (max-width: 1111px) {
  #main-navbar:not(.header-nav) .container-lg.py-2::after {
    width: 450px;
    height: 450px;
    bottom: 0;
    right: -15%;
  }
}
#main-navbar.header-nav {
  background-color: #051C45;
}
#main-navbar #primary-menu {
  transition: all 0.3s;
  opacity: 1;
  position: relative;
}
#main-navbar #primary-menu.search-open {
  right: 500px;
  opacity: 0;
}
#main-navbar .container-lg {
  background-color: #fff;
}
#main-navbar,
#main-navbar .nav-link {
  transition: all 0.5s ease;
}
#main-navbar .nav-item {
  position: relative;
}
#main-navbar .navbar-logo {
  max-width: 155px;
  object-fit: contain;
}
#main-navbar .navbar-brand {
  white-space: normal;
}
#main-navbar .company-name {
  /*rtl:raw:
  font-size: 24px;
  */
  /*rtl:remove*/
  font-size: 1.5rem;
}
#main-navbar .nav-link {
  font-size: 15px;
  padding-left: 1rem;
  color: #000;
  font-weight: 500;
}
#main-navbar [id*=menu-item-] {
  color: #fff;
}
@media (max-width: 1111px) {
  #main-navbar [id*=menu-item-] {
    color: #000;
    font-weight: 700 !important;
  }
}
#main-navbar [id*=menu-item-]::before {
  content: "";
  height: 3px;
  border-radius: 25px;
  position: absolute;
  left: 1rem;
  bottom: 10px;
  width: 0;
  background-color: #FFAA00;
  transition: 0.5s all ease;
}
#main-navbar [id*=menu-item-].active {
  color: #FFAA00;
}
#main-navbar [id*=menu-item-].active::before {
  width: 23px;
}
#main-navbar [id*=menu-item-]:hover {
  color: #FFAA00;
}
#main-navbar [id*=menu-item-]:hover::before {
  width: 23px;
}
#main-navbar .search-btn {
  background-color: #F6F6F6;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  z-index: 100;
  position: relative;
}
#main-navbar .dropdown {
  position: relative;
}
#main-navbar .dropdown .nav-link {
  padding-right: 2.3rem;
  padding-left: 0.9rem;
}
#main-navbar .dropdown .dropdown-toggle {
  text-decoration: none;
  position: absolute;
  padding: 0.8rem;
  top: 0.2rem;
  right: 0;
}
@media (max-width: 1111px) {
  #main-navbar .dropdown .dropdown-toggle {
    color: #000;
  }
}
@media (min-width: 1112px) {
  #main-navbar .dropdown .dropdown-toggle {
    color: #fff;
  }
}
#main-navbar .dropdown-menu {
  line-height: 1.8rem;
}
#main-navbar .dropdown-menu {
  line-height: 1.8rem;
}
#main-navbar .dropdown-menu.mobile-dropdown {
  border-left: solid 1px #E6E6E6 !important;
}
#main-navbar .dropdown-menu.mobile-dropdown .level-2-child:before {
  content: "";
  border: 4px solid #FFAA00;
  border-radius: 50rem;
  width: 6px;
  margin-right: 8px;
  font-size: 17px;
  display: inline-block;
  margin-top: -5px;
}
#main-navbar.navbar-light {
  background-color: #fff;
}
#main-navbar.navbar-light .logo-white {
  display: none;
}
#main-navbar.navbar-light .dropdown-toggle {
  color: #C5C5C5;
}

@media (max-width: 1111.98px) {
  .dropdown .dropdown-toggle {
    font-size: 0.875rem;
  }

  .flag-thumb {
    width: 1.875rem;
  }

  #main-navbar {
    padding-top: 0;
    background-color: #fff;
  }
  #main-navbar .navbar-toggler .icon-bar {
    background-color: #309AA7;
  }
  #main-navbar.show {
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
  }
  #main-navbar .dropdown-menu {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  #main-navbar .navbar-collapse {
    background-color: #fff;
  }

  #primary-menu {
    transition: padding-right 0.3s;
    padding-right: 0;
  }
  #primary-menu.search-open {
    padding-right: 500px;
  }
  #primary-menu .nav-link {
    padding: 0.9rem !important;
    max-width: calc(100% - 1.9rem);
  }
  #primary-menu .dropdown .dropdown-toggle {
    top: 4px;
  }
  #primary-menu .dropdown-menu {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  #main-navbar .company-name {
    font-size: 0.85rem;
  }
}
/* ============ desktop view ============ */
@media all and (min-width: 1112px) {
  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
/* ============ desktop view .end// ============ */
/**
Navbar Toggler
 */
#main-navbar .navbar-toggler {
  border: none;
  background: transparent !important;
  outline: none !important;
  width: 25px;
  box-shadow: none !important;
}
#main-navbar .navbar-toggler .icon-bar {
  display: block;
  width: 100%;
  border-radius: 1px;
  height: 3px;
  transition: all 0.3s;
  position: relative;
}
#main-navbar .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 6px;
}
#main-navbar .navbar-toggler .icon-bar.top-bar {
  animation: ease 0.7s navbar-toggler-top-2 forwards;
}
#main-navbar .navbar-toggler .icon-bar.middle-bar {
  animation: ease 0.7s navbar-toggler-scaled-2 forwards;
}
#main-navbar .navbar-toggler .icon-bar.bottom-bar {
  animation: ease 0.7s navbar-toggler-bottom-2 forwards;
}
#main-navbar.navbar-light .navbar-toggler .icon-bar {
  background-color: #309AA7;
}
#main-navbar.navbar-dark .navbar-toggler .icon-bar {
  background-color: #fff;
}
#main-navbar .navbar-toggler[aria-expanded=true] .icon-bar {
  background: #309AA7;
}
#main-navbar .navbar-toggler[aria-expanded=true] .icon-bar.top-bar {
  animation: ease 0.7s navbar-toggler-top forwards;
}
#main-navbar .navbar-toggler[aria-expanded=true] .icon-bar.middle-bar {
  animation: ease 0.7s navbar-toggler-scaled forwards;
}
#main-navbar .navbar-toggler[aria-expanded=true] .icon-bar.bottom-bar {
  animation: ease 0.7s navbar-toggler-bottom forwards;
}

@keyframes navbar-toggler-top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 9px;
    transform: rotate(0);
  }
  100% {
    top: 9px;
    transform: rotate(45deg);
  }
}
@keyframes navbar-toggler-top-2 {
  0% {
    top: 9px;
    transform: rotate(45deg);
  }
  50% {
    top: 9px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}
@keyframes navbar-toggler-bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 9px;
    transform: rotate(135deg);
  }
}
@keyframes navbar-toggler-bottom-2 {
  0% {
    bottom: 9px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
@keyframes navbar-toggler-scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes navbar-toggler-scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
#site-footer {
  padding: 0;
  font-size: 1rem;
  color: #000;
}
#site-footer .footer-up {
  background-color: #004050;
  z-index: 1;
}
#site-footer .footer-up::before {
  content: "";
  width: 864px;
  height: 864px;
  position: absolute;
  top: -30%;
  left: -15%;
  background-image: url("../img/footer-bg-design.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  z-index: -2;
}
@media (max-width: 1111px) {
  #site-footer .footer-up::before {
    top: -5%;
    left: -50%;
  }
}
#site-footer .footer-up::after {
  content: "";
  width: 864px;
  height: 864px;
  position: absolute;
  bottom: -100%;
  right: -15%;
  background-image: url("../img/footer-bg-design.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  z-index: -2;
}
@media (max-width: 1111px) {
  #site-footer .footer-up::after {
    bottom: -5%;
    right: -50%;
  }
}
#site-footer .footer-up i {
  color: #FFAA00;
}
#site-footer .footer-up .footer-nav-link {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
}
#site-footer .footer-up a.footer-nav-link:hover {
  color: #FFAA00;
}
#site-footer .footer-up .footer-up_secondary .footer-menu-h h4, #site-footer .footer-up .footer-up_secondary .footer-menu-h .h4 {
  font-weight: 600;
  font-size: 1rem;
  color: #fff;
}
#site-footer .footer-up .footer-up_secondary .footer-menu-h:after {
  content: "";
  display: inline-block;
  vertical-align: center;
  width: 50px;
  margin: 7px 0 20px 0;
  border-top: 3px solid #FFAA00;
  border-radius: 25px;
}
#site-footer .footer-up .footer-up_secondary .nav-link {
  padding: 10px 0 25px 0;
}
#site-footer .footer-up .footer-up_secondary .nav-link:hover {
  opacity: 1;
}
@media (min-width: 1112px) {
  #site-footer .footer-up .footer-up_secondary i {
    padding-right: 20px;
  }
}
#site-footer .footer-up .footer-up_secondary .d-flex {
  padding: 10px 0;
}
#site-footer .footer-up .footer-up_secondary .col-md-3 div {
  padding: 10px 0;
}
#site-footer .footer-down p {
  color: #fff;
}
#site-footer .nav-link {
  font-size: 1rem;
  color: #FFF;
  font-weight: 700;
}
#site-footer a {
  color: #212529;
}
#site-footer a:hover {
  color: #000;
}
#site-footer .fa, #site-footer .fab, #site-footer .fad, #site-footer .fal, #site-footer .far, #site-footer .fas {
  font-size: 1.25rem;
  line-height: unset;
}
#site-footer .social-icons i {
  font-weight: 400;
  align-self: center;
  color: #fff;
  transition: all 0.3s;
}
#site-footer .social-icons i:hover {
  color: #FFAA00;
}
#site-footer img {
  height: 4rem;
}

.members-logo-1:before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../img/member1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

.members-logo-2:before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../img/member2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

.members-logo-3:before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../img/member3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

/*rtl:raw:
#site-footer .footer-up::after {
    transform:rotateY(180deg);
}
*/
.carousel {
  height: calc(100vh);
  overflow: hidden;
}
@media (min-width: 1112px) {
  .carousel {
    margin-top: -191px;
  }
}
@media (max-width: 1111px) {
  .carousel {
    margin-top: -81px;
  }
}
.carousel .carousel-indicators {
  margin: 0 auto 2rem auto;
}
.carousel .carousel-indicators button {
  border-radius: 100%;
  display: block;
  margin: 10px 0;
  padding: 2px;
}
.carousel .carousel-indicators button.active {
  border: solid #fff 1px;
  -webkit-background-clip: content-box;
  /* for Safari */
  background-clip: content-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
}
.carousel .carousel-caption {
  position: absolute;
  right: 0;
  bottom: 16%;
  left: 0;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: left;
  margin: 0 auto 0 auto;
}
.carousel .carousel-caption h1, .carousel .carousel-caption .h1 {
  font-size: 40px;
  /*rtl:raw:
      font-size: 60px;
  */
  font-weight: bolder;
  line-height: 69px;
  margin-bottom: 24px;
}
.carousel .carousel-inner {
  height: 100%;
}
.carousel .carousel-inner .carousel-item {
  height: 100%;
}
.carousel .carousel-inner .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel .carousel-inner .carousel-item:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000066;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5497549362) 0%, rgba(0, 0, 0, 0) 100%);
}

@media (max-width: 1111.98px) {
  .carousel {
    margin-top: -0.67rem;
  }
}
/*rtl:ignore*/
.breadcrumb {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 24px;
}

/*rtl:ignore*/
.breadcrumb-item:not(.active) a {
  text-decoration: none !important;
  color: #FFAA00 !important;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.breadcrumb-item:not(.active) a:hover {
  color: #fff !important;
}
.breadcrumb-item.active {
  font-weight: bold;
  color: #fff;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

/*!rtl:remove*/
.fa-long-arrow-end:before {
  content: "";
}

/*!rtl:raw:
.fa-long-arrow-end:before {
    content: "\f177";
}
*/
/*!rtl:remove*/
.fa-long-arrow-start:before {
  content: "";
}

/*!rtl:raw:
.fa-long-arrow-start:before {
    content: "\f178";
}
*/
/*!rtl:remove*/
.fa-chevron-end:before {
  content: "";
}

/*!rtl:raw:
.fa-chevron-end:before {
    content: "\f053";
}
*/
/*!rtl:remove*/
.fa-chevron-start:before {
  content: "";
}

/*!rtl:raw:
.fa-chevron-start:before {
    content: "\f054";
}
*/
.post-filter .card-body {
  border-radius: 10px;
  font-weight: 500;
}
.post-filter .filter-search-button {
  border-radius: 10px 0 0 10px;
  background-color: #F6F6F6;
}
.post-filter .filter-search-button i {
  color: #000;
}
.post-filter .filter-search-input {
  background-color: #F6F6F6;
  color: #000;
  font-weight: 500;
}
.post-filter .filter-search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C5C5C5;
  opacity: 1;
  /* Firefox */
}
.post-filter .filter-search-input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #C5C5C5;
}
.post-filter .filter-search-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #C5C5C5;
}
.post-filter .form-label {
  font-weight: 500;
}
.post-filter .filter-clear-buttons .btn {
  padding-top: 11px;
  padding-bottom: 9px;
}

.no-items .no-items-svg {
  position: relative;
}
.no-items .no-items-svg:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 39px;
  position: absolute;
  top: 32%;
  right: 36%;
  content: "";
}

/*rtl:ignore*/
.searchbar-non-collapse {
  width: 450px;
  right: 0;
}
@media (max-width: 1111.98px) {
  .searchbar-non-collapse {
    width: 350px;
  }
}
.searchbar-non-collapse .input-group-addon i {
  color: #309AA7;
}
.searchbar-non-collapse .search-web-form input::-webkit-input-placeholder {
  color: #797979;
}

/*rtl:ignore*/
.searchbar-collapse {
  width: 50px;
  left: 0;
}
.searchbar-collapse .input-group-addon i {
  color: #000;
}
.searchbar-collapse .search-web-form input::-webkit-input-placeholder {
  color: transparent;
}

/*rtl:ignore*/
#navbar-search-2 {
  height: 50px;
}

/*rtl:ignore*/
.searchbar {
  top: calc(50% - (50px / 2));
  height: 50px;
  background-color: #000;
  transition: all 0.3s;
  border-radius: 25px;
  z-index: -1;
}
.searchbar .input-group-addon {
  padding-top: 14px;
  z-index: 100;
}
.searchbar .input-group-addon i {
  transition: color 0.3s;
}
.searchbar .search-web-form input {
  padding-left: 15px !important;
}
.searchbar .search-web-form input::-webkit-input-placeholder {
  /*rtl:raw:
  padding-right: 5px !important;
  */
  transition: color 0.3s;
}

/*rtl:ignore*/
.searchbar-web {
  top: calc(50% - (50px / 2));
  height: 50px;
  background-color: #000;
  transition: all 0.3s;
  border-radius: 10px;
  z-index: -1;
}
.searchbar-web .input-group-addon {
  padding-top: 14px;
  z-index: 100;
}
.searchbar-web .input-group-addon i {
  transition: color 0.3s;
}
.searchbar-web .search-web-form input {
  padding-left: 15px !important;
}
.searchbar-web .search-web-form input::-webkit-input-placeholder {
  transition: color 0.3s;
}

/*rtl:ignore*/
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.form-group label {
  margin-left: 12px;
}
.form-group .search-filter {
  height: 50px;
  background-color: #000;
  border-radius: 10px;
  padding-left: 15px !important;
  outline: none;
}
.form-group .search-filter::-webkit-input-placeholder {
  color: #797979;
}

/*rtl:ignore*/
.searchbar-filter {
  display: flex;
  flex-direction: row;
}
.searchbar-filter .filter-options {
  margin-bottom: 1.5rem;
  margin-top: 33px;
}
.searchbar-filter label {
  font-weight: 600;
  margin-bottom: 9px;
}

/*rtl:ignore*/
.searchbar .search-web-form button {
  background-color: #000;
  height: 55px;
}
.searchbar .search-web-form input {
  outline: none;
  background-color: #000;
}

/*rtl:ignore*/
.searchbar-web .search-web-form button {
  background-color: #000;
  height: 55px;
}
.searchbar-web .search-web-form input {
  outline: none;
  background-color: #000;
}

/*rtl:ignore*/
.search-web-button {
  border-radius: 25px;
  z-index: 101;
}
.search-web-button.active {
  border-radius: 0 25px 25px 0;
}

.post {
  /*i:first-child {
      padding: 1.563rem 1.875rem;
      font-size: 40px;
      border-radius: 50px;
      background-color: rgb($primary, 0.05);
      color: $primary;
      transition: all 0.3s;
  }*/
}
.post p {
  color: #000;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
}
.post h6, .post .h6 {
  color: #C5C5C5;
}
.post .post-item-icon {
  background-color: rgba(140, 140, 140, 0.05);
  color: #309AA7;
  transition: all 0.3s;
  width: 90px;
}
.post .post-item-icon i {
  font-size: 40px;
  color: #8C8C8C;
  transition: all 0.3s;
}
.post .card-body {
  height: 135px;
}
.post:hover .post-item-icon {
  background-color: rgba(48, 154, 167, 0.15);
  transition: all 0.3s;
}
.post:hover .post-item-icon i {
  color: #309AA7;
  transition: all 0.3s;
}
.post:hover p {
  color: #309aa7;
  transition: all 0.3s;
}
.post:hover h4, .post:hover .h4 {
  color: #309aa7;
  transition: all 0.3s;
}

.publication p {
  color: #000;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
}
.publication h6, .publication .h6 {
  color: #C5C5C5;
}
.publication .card {
  margin-top: -38px;
  z-index: -1;
}
.publication .card-body {
  height: 125px;
}
.publication img {
  box-shadow: 0 19px 23px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}
.publication:hover .publication-title {
  color: #309aa7;
  transition: all 0.3s;
}

.post-title i {
  padding: 1.563rem 1.875rem;
  font-size: 45px;
  border-radius: 50px;
  background-color: rgba(48, 154, 167, 0.05);
  color: #309AA7;
  transition: all 0.3s;
}
.post-title .publication-thumb {
  box-shadow: 0 19px 23px 0 rgba(0, 0, 0, 0.16);
}

.link-hover-primary {
  color: #C5C5C5;
}
.link-hover-primary:hover {
  color: #309AA7;
}

.btn-attachments {
  padding: 0.5rem 1rem;
}

.new-post {
  right: 10px;
  z-index: 1;
  top: -15px;
  background-color: #00DFD8;
  color: #fff;
}

.updated-post {
  right: 10px;
  z-index: 1;
  top: -15px;
  background-color: #FFAA00;
  color: #fff;
}

.post-filter input {
  outline: none;
}

@media (min-width: 1112px) {
  .collapse.dont-collapse-lg {
    display: block;
    height: auto !important;
    visibility: visible;
  }

  .post:first-of-type {
    margin-top: 0;
  }

  .publication:first-of-type {
    margin-top: 0;
  }
}
/**
Select 2
 */
.select2-container--bootstrap-5 {
  display: block;
}
select + .select2-container--bootstrap-5 {
  z-index: 1;
}

.select2-container--bootstrap-5 *:focus {
  outline: 0;
}
.select2-container--bootstrap-5 .select2-selection {
  width: 100%;
  min-height: calc(1.5em + 1.45rem);
  padding: 0.725rem 1.25rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 0 solid #fff;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap-5 .select2-selection {
    transition: none;
  }
}
.select2-container--bootstrap-5.select2-container--focus .select2-selection, .select2-container--bootstrap-5.select2-container--open .select2-selection {
  border-color: #E6E6E6;
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
}
.select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
  border-bottom: 0 solid transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
  border-top: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5 .select2-search {
  width: 100%;
}
.select2-container--bootstrap-5 .select2-search--inline .select2-search__field {
  vertical-align: top;
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
  position: absolute;
  top: 50%;
  right: 3.75rem;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  transform: translateY(-50%);
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear > span,
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear > span {
  display: none;
}

.select2-container--bootstrap-5 + .select2-container--bootstrap-5 {
  z-index: 1056;
}
.select2-container--bootstrap-5 .select2-dropdown {
  z-index: 1056;
  overflow: hidden;
  color: #212529;
  background-color: #fff;
  border-color: #E6E6E6;
  border-radius: 0;
}
.select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
  border-top: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
  border-bottom: 0 solid transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-search {
  padding: 0.725rem 1.25rem;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
  display: block;
  width: 100%;
  padding: 0.725rem 1.25rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #fff;
  appearance: none;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
    transition: none;
  }
}
.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: #E6E6E6;
  box-shadow: 0 0 0 0.25rem rgba(48, 154, 167, 0.25);
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options:not(.select2-results__options--nested) {
  max-height: 15rem;
  overflow-y: auto;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.725rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__message {
  color: #E6E6E6;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {
  color: #000;
  background-color: #e9ecef;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
  color: #000;
  background-color: #309AA7;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--disabled, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-disabled=true] {
  color: #6c757d;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
  padding: 0.725rem 0.625rem;
  font-weight: 500;
  line-height: 1.5;
  color: #6c757d;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding: 0.725rem 1.25rem;
}

.select2-container--bootstrap-5 .select2-selection--single {
  padding: 0.725rem 3.75rem 0.725rem 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23C5C5C5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 16px 12px;
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
  padding: 0;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-weight: 500;
  line-height: 1.5;
  color: #E6E6E6;
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__arrow {
  display: none;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.35em 0.65em;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
  font-size: 1rem;
  color: #212529;
  cursor: auto;
  border: 0 solid #fff;
  border-radius: 0;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  margin-right: 0.25rem;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  border: 0;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove > span {
  display: none;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-search {
  display: block;
  width: 100%;
  height: 1.5rem;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
  width: 100%;
  height: 1.5rem;
  margin-top: 0;
  margin-left: 0;
  font-family: inherit;
  line-height: 1.5;
  background-color: transparent;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
  right: 1.25rem;
}

.select2-container--bootstrap-5.select2-container--disabled .select2-selection, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection {
  color: #6c757d;
  cursor: not-allowed;
  background-color: #e9ecef;
  border-color: #fff;
  box-shadow: none;
}
.select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__clear, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__choice, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__choice {
  cursor: not-allowed;
}
.select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}
.select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__rendered:not(:empty), .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__rendered:not(:empty) {
  padding-bottom: 0;
}
.select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__rendered:not(:empty) + .select2-search, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__rendered:not(:empty) + .select2-search {
  display: none;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu).select2-container--bootstrap-5 .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu).select2-container--bootstrap-5 .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-text ~ .select2-container--bootstrap-5 .select2-selection,
.input-group > .btn ~ .select2-container--bootstrap-5 .select2-selection,
.input-group > .dropdown-menu ~ .select2-container--bootstrap-5 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .select2-container--bootstrap-5 {
  flex-grow: 1;
}
.input-group .select2-container--bootstrap-5 .select2-selection {
  height: 100%;
}

.is-valid + .select2-container--bootstrap-5 .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5 .select2-selection {
  border-color: #198754;
}
.is-valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-valid + .select2-container--bootstrap-5.select2-container--open .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.is-valid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
  border-bottom: 0 solid transparent;
}
.is-valid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
  border-top: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.is-invalid + .select2-container--bootstrap-5 .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5 .select2-selection {
  border-color: #dc3545;
}
.is-invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.is-invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
  border-bottom: 0 solid transparent;
}
.is-invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
  border-top: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--bootstrap-5 .select2--small.select2-selection {
  min-height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0rem;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-selection__clear,
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.125rem 0.125rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-selection__clear:hover,
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-search,
.select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-search .select2-search__field,
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-search,
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-search .select2-search__field {
  height: 1.5em;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown {
  border-radius: 0rem;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-search .select2-search__field {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
  padding: 0.25rem 0.25rem;
}
.select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding: 0.25rem 0.5rem;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--single {
  padding: 0.25rem 3.75rem 0.25rem 0.5rem;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 0.875rem;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.125rem 0.125rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear {
  right: 0.5rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection {
  min-height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.625rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-selection__clear,
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-selection__clear:hover,
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-search,
.select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-search .select2-search__field,
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-search,
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-search .select2-search__field {
  height: 1.5em;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown {
  border-radius: 0.625rem;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-search .select2-search__field {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
  padding: 0.5rem 0.5rem;
}
.select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding: 0.5rem 1rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--single {
  padding: 0.5rem 3.75rem 0.5rem 1rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 1.25rem;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear {
  right: 1rem;
}

.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection {
  min-height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.125rem 0.125rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search,
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search .select2-search__field,
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search,
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
  height: 1.5em;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown {
  border-radius: 0rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
  padding: 0.25rem 0.25rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding: 0.25rem 0.5rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single {
  padding: 0.25rem 3.75rem 0.25rem 0.5rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 0.875rem;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0.125rem 0.125rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
}
.form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
  right: 0.5rem;
}

.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection {
  min-height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.625rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search,
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search .select2-search__field,
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search,
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
  height: 1.5em;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown {
  border-radius: 0.625rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
  padding: 0.5rem 0.5rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
  padding: 0.5rem 1rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single {
  padding: 0.5rem 3.75rem 0.5rem 1rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 1.25rem;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem 0.5rem;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='gray'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
}
.form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
  right: 1rem;
}

.select2-container--bootstrap-5 .select2-dropdown {
  border: 0;
  box-shadow: 0 0.188rem 1.625rem rgba(0, 0, 0, 0.05);
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideUp;
  border-radius: 0rem;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: #fff;
  box-shadow: none;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
  font-weight: 400;
}
.select2-container--bootstrap-5 .select2-selection {
  border: 1px solid #E6E6E6;
  border-radius: 15px;
  box-shadow: none;
}
.select2-container--bootstrap-5.select2-container--open .select2-selection, .select2-container--bootstrap-5.select2-container--focus .select2-selection {
  box-shadow: none;
}
.select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
  border-bottom: none;
}
.select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
  border-top: none;
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
  /*rtl:raw:
      text-align: right;
  */
}

.custom-pagination {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
}
.custom-pagination .pagination::-webkit-scrollbar {
  display: none;
}
.custom-pagination .pagination {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.page-item {
  transition: all 0.15s ease-in-out;
}
.page-item .page-link {
  background-color: #F6F6F6;
}
.page-item .page-link.border {
  background-color: transparent;
  border: 1px solid #FFAA00 !important;
}
.page-item .page-link.border i {
  color: #FFAA00;
}
.page-item .page-link.border:hover {
  background-color: #FFAA00;
}
.page-item .page-link.border:hover i {
  color: #fff;
}
.page-item.disabled .page-link {
  transition: all 0.15s ease-in-out;
  color: #C5C5C5;
  background-color: transparent;
}
.page-item.active .page-link {
  background-color: #F6F6F6;
  color: #FFAA00;
  transition: all 0.15s ease-in-out;
}
.page-item:not(.disabled):hover .page-link {
  color: #FFAA00;
}

.page-link {
  border-radius: 5px !important;
}

/**
Utilities
 */
.status-box {
  height: 1.875rem;
  right: 0.625rem;
  top: -15px;
}

.status-box-new {
  color: #00CA86;
  background-color: #d9f7ed;
}

.status-box-updated {
  color: #F7B400;
  background-color: #fef4d9;
}

.link-grey-4e {
  color: #000;
}
.link-grey-4e:hover, .link-grey-4e:focus {
  color: #309ba8;
}

.link-grey-70 {
  color: #000;
}
.link-grey-70:hover, .link-grey-70:focus {
  color: #309ba8;
}

.link-grey-aa {
  color: #000;
}
.link-grey-aa:hover, .link-grey-aa:focus {
  color: #309ba8;
}

.link-grey-c5 {
  color: #C5C5C5;
}
.link-grey-c5:hover, .link-grey-c5:focus {
  color: #309ba8;
}

.link-grey-e6 {
  color: #E6E6E6;
}
.link-grey-e6:hover, .link-grey-e6:focus {
  color: #309ba8;
}

.link-grey-ec {
  color: #ECECEC;
}
.link-grey-ec:hover, .link-grey-ec:focus {
  color: #309ba8;
}

.link-grey-f1 {
  color: #F1F1F1;
}
.link-grey-f1:hover, .link-grey-f1:focus {
  color: #309ba8;
}

.link-grey-f6 {
  color: #F6F6F6;
}
.link-grey-f6:hover, .link-grey-f6:focus {
  color: #309ba8;
}

.link-grey-fa {
  color: #FAFAFA;
}
.link-grey-fa:hover, .link-grey-fa:focus {
  color: #309ba8;
}

.title-underscore {
  position: relative;
  font-size: 1.5rem;
}
.title-underscore::before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 3rem;
  height: 2.5px;
  bottom: -0.825rem;
  background-color: #309AA7;
  border-radius: 10px;
}
.title-underscore::after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 1rem;
  height: 2.5px;
  left: calc(3rem + .6rem);
  bottom: -0.825rem;
  background-color: #309AA7;
  border-radius: 10px;
}

.title-underscore-text-center::before {
  left: 47%;
}
.title-underscore-text-center::after {
  left: 52%;
}

.title-underscore-white::before {
  background-color: #fff;
}
.title-underscore-white::after {
  background-color: #fff;
}

.title-related-line-above:before {
  width: 46px;
  border-bottom: solid 3px #309AA7;
  position: absolute;
  left: -2px;
  top: -8px;
  content: "";
  border-radius: 10px;
}

.page-blocks {
  line-height: 24px;
  color: #000;
  /* // Done
   .action-card-block {
       .content-row {
           min-height: 300px;
       }

       img {
           height: 100%;
       }

       a {
           text-decoration: none;
       }

       .btn {
           border-color: $grey-c5;
           color: $grey-c5;
           font-weight: $font-weight-bold;

           &:hover {
               background-color: $grey-4e;
               color: $white;
           }
       }
   }*/
}
.page-blocks .paragraph-block {
  text-align: justify;
}
.page-blocks .quote-block .blockquote-text {
  line-height: var(--bs-body-line-height);
}
.page-blocks .image-text-block h5.section-title, .page-blocks .image-text-block .section-title.h5 {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}
.page-blocks .image-text-block .img-fluid {
  width: 390px;
}
.page-blocks h1, .page-blocks .h1 {
  font-size: 2.5rem;
}
.page-blocks h2, .page-blocks .h2 {
  font-size: 2.375rem;
}
.page-blocks h3, .page-blocks .h3 {
  font-size: 2.25rem;
}
.page-blocks h4, .page-blocks .h4 {
  font-size: 2.125rem;
}
.page-blocks h5, .page-blocks .h5 {
  font-size: 2rem;
}
.page-blocks h6, .page-blocks .h6 {
  font-size: 1.875rem;
}
.page-blocks .image-divider-block .primary-transparent {
  background-color: rgba(48, 154, 167, 0.67);
}
.page-blocks .image-divider-block .primary-transparent .section-title {
  opacity: 1 !important;
}
.page-blocks .image-divider-block .primary-transparent .section-subtext {
  opacity: 1 !important;
}

/*!rtl:raw:
.page-blocks {
    word-spacing: 5px;
}
*/
/*Page Divider*/
.page-divider {
  position: relative;
}

.page-divider-child {
  height: 250px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-divider-child h1, .page-divider-child .h1 {
  z-index: 1;
  color: #FFF;
}
.page-divider-child h5, .page-divider-child .h5 {
  z-index: 1;
  color: #FFF;
}

.page-divider-child:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #2A2A2A linear-gradient(-90deg, #2A2A2A 0%, #2A2A2A 100%);
  opacity: 0.9;
  top: 0;
}

/*news Box*/
.action-card-box {
  min-height: 300px;
}
.action-card-box .card-body {
  padding: 2.5rem 2rem;
}
.action-card-box div {
  overflow: hidden;
}
@media (max-width: 1111.98px) {
  .action-card-box img {
    width: 100%;
  }
}
@media (min-width: 1112px) {
  .action-card-box img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.action-card-box i {
  transition: all 0.3s;
}
.action-card-box p {
  line-height: 31px;
}
.action-card-box .action-card-read-more:hover {
  margin-bottom: 3rem;
}
.action-card-box .action-card-read-more:hover i {
  transform: translate(5px, 0);
  transition: all 0.3s;
}

/*quote box*/
.quote-box {
  /*background-image: url("../img/quote-gray.svg");
  background-repeat: no-repeat;
  background-size: 200px;
  background-position-x: 99%;
  background-position-y: 97%;*/
  background-color: transparent;
  position: relative;
}
.quote-box .quote-symbol {
  position: absolute;
  top: -100px;
  left: -15px;
}
@media (min-width: 1112px) {
  .quote-box .card-body {
    overflow: hidden;
  }
  .quote-box .card-body::before {
    content: "";
    width: 145px;
    height: 95px;
    position: absolute;
    bottom: 0;
    right: -2px;
    background-image: url("../images/quote-bg.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
  }
  .quote-box .card-body:after {
    content: "";
    height: 165px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 245px;
    overflow: hidden;
    background-image: url("../img/quote.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    z-index: -1;
  }
}

/*video embed*/
.video-embed button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.video-embed button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: btnanim;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-color: #309AA7;
  border-radius: 50%;
  z-index: -1;
  width: 50px;
  height: 50px;
}
@keyframes btnanim {
  0% {
    opacity: 1;
    width: 50px;
    height: 50px;
  }
  50% {
    opacity: 0.5;
    width: 70px;
    height: 70px;
  }
  100% {
    opacity: 0;
    width: 80px;
    height: 80px;
  }
}

/*table Blocks*/
.table :not(:first-child) {
  border-top: 0;
}
.table th {
  border: none;
}
.table tr {
  vertical-align: top;
}
.table tr:last-child td {
  border: none;
}

/*related content*/
.related-content .card {
  overflow: hidden;
}
.related-content .card:hover .hover-effect > i {
  bottom: 15px;
  transition: bottom 0.5s;
}
.related-content .d-flex i {
  font-size: 3.25rem !important;
}
.related-content .d-flex .hover-effect i {
  font-size: 7rem !important;
  right: 20px;
  bottom: -150px;
  opacity: 0.5;
  transition: bottom 0.5s;
  /*rtl:ignore*/
  transform: rotate(-20deg);
}

/*side menu*/
.side-menu a {
  padding: 15px 0;
  font-size: 1rem;
  color: #000;
}
.side-menu .nav-item {
  border-bottom: 1px solid #E6E6E6;
}
.side-menu .nav-item:last-of-type {
  border-bottom: 0px solid #E6E6E6;
}
.side-menu .active {
  color: #6c757d;
}

/*Gallery*/
.gallery {
  position: relative;
}
.gallery .black-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: black;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4497549362) 0%, rgba(0, 0, 0, 0) 100%);
}
.gallery .card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: 0.5s;
}
.gallery .card:hover img {
  filter: brightness(90%);
  transition: 0.5s;
}
.gallery .card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
}
.gallery .card-body * {
  margin: 5px;
  color: #FFF;
}
.gallery .card-body h6, .gallery .card-body .h6 {
  font-weight: 400;
}

.post-item-datetime {
  font-size: 14px;
}

.download-accordion {
  border-radius: 15px !important;
}
.download-accordion .accordion-button {
  border-radius: 15px !important;
}
.download-accordion .accordion-button:not(.collapsed) {
  border-radius: 15px 15px 0 0 !important;
}
.download-accordion .accordion-button::after {
  position: absolute;
  top: 35%;
  right: 20px;
}

/*News*/
.news .card {
  height: 440px;
  overflow: hidden;
  /*.news-btn {
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      i {
          padding-left: 5px;
          font-size: 10px;
          transition: 0.2s;
      }
      &:hover {
          i {
              padding-left: 8px;
              transition: 0.2s;
          }
      }
  }*/
}
.news .card h5, .news .card .h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news .card img {
  width: 100%;
  height: 294px;
  object-fit: cover;
  transition: 0.5s;
}
.news .card:hover {
  /*.news-btn {
      height: 30px;
      transition: 1s;
  }*/
}
.news .card:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}
@media (max-width: 887.98px) {
  .news .card {
    height: 100%;
    /*.news-btn {
        height: 30px;
    }
    &:hover {
        img {
            height: 250px;
        }
    }*/
  }
}

.department .card {
  transition: all 0.3s ease-out;
  border: 1px solid #fff;
  height: 100%;
  min-height: 171px;
}
.department .card:hover {
  transition: all 0.3s ease-out;
  border: 1px solid #309AA7 !important;
}
.department .card:hover h4, .department .card:hover .h4 {
  color: #309AA7;
  transition: color 0.3s;
}
.department .card h4, .department .card .h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  transition: color 0.3s;
}
.department img {
  height: 63px;
  border-radius: 50%;
  box-shadow: 0 10px 26px 0 rgba(0, 0, 0, 0.1);
}

#department-single-page .department-show-title i {
  height: 74px;
  width: 74px;
  background-color: rgba(48, 154, 167, 0.1);
  position: relative;
}
#department-single-page .department-show-title i:before {
  color: #309AA7;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#department-single-page .department-show-title h4, #department-single-page .department-show-title .h4 {
  font-size: 22px;
}

.department-card i {
  height: 74px;
  width: 74px;
  background-color: rgba(48, 154, 167, 0.1);
  position: relative;
}
.department-card i:before {
  color: #309AA7;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.post-show .title-area {
  height: 90px;
}
.post-show .title-area .post-item-icon {
  background-color: rgba(48, 154, 167, 0.05);
  color: #309AA7;
  transition: all 0.3s;
  width: 90px;
}
.post-show .title-area .post-item-icon i {
  font-size: 40px;
  color: #309AA7;
  transition: all 0.3s;
}
.post-show .title-area h3, .post-show .title-area .h3 {
  font-size: 1.125rem;
}
.post-show .title-area .status-box {
  font-size: 0.875rem;
}
.post-show .title-area .published-at {
  font-size: 14px;
  color: #C5C5C5;
}
.post-show .attachments .card-body {
  padding: 33px 30px;
}
.post-show .attachments h4, .post-show .attachments .h4 {
  font-size: 1.25rem;
}
.post-show .attachments .file-size {
  font-size: 0.75rem;
}
.post-show .attachments hr {
  color: #000;
  height: 2px;
}
.post-show .attachments .attachment + .attachment {
  border-top: 1px #ECECEC solid;
}

/**
Pages
 */
.page-sidebar-menu {
  border-left: 1px solid #E6E6E6;
}
.page-sidebar-menu .dropstart {
  position: relative;
}
.page-sidebar-menu .dropstart .nav-link {
  font-size: 15px;
  padding-right: 0;
  padding-left: 0.9rem;
  font-weight: 500;
}
.page-sidebar-menu .dropstart .dropdown-toggle {
  text-decoration: none;
  position: absolute;
  padding: 0;
  top: 0;
  right: 0.5rem;
}
.page-sidebar-menu .sidebar-item {
  color: #000;
  padding-left: 15px;
  transition: all 0.3s;
}
.page-sidebar-menu .sidebar-item:hover {
  color: #309AA7;
}
.page-sidebar-menu .sidebar-item.active {
  color: #309AA7;
}

/**
Staff Directory
 */
.staff-directory .table {
  font-weight: normal !important;
}
.staff-directory .table thead, .staff-directory .table tbody, .staff-directory .table tfoot, .staff-directory .table tr, .staff-directory .table td, .staff-directory .table th {
  border-style: none;
}
.staff-directory .table thead th {
  border-bottom-width: 0;
}
.staff-directory .table tbody {
  background-color: #fff;
  border-radius: 7px;
}
.staff-directory .table tbody .staff-records:last-child th {
  border-bottom-width: 0;
}
.staff-directory .table > :not(:first-child) {
  border-top: unset;
}
.staff-directory .table > :not(caption) > * > * {
  padding: 1.25rem 1.25rem;
}

.btn .filter-badge {
  position: relative;
  top: 22px;
}

/**
Contact Us
 */
@media (min-width: 1111px) {
  .contact-us-page {
    margin-bottom: 5rem;
  }
}
.contact-us-page .form-control {
  box-shadow: unset;
  border-color: #E6E6E6;
  background-color: transparent;
}
.contact-us-page .contact-details .far {
  color: #309AA7;
}

.super-search .search-dropdown .search-and-icon {
  padding-left: 1.625rem;
}

.administrative-framework .stupid-card {
  min-height: 100px;
}
.administrative-framework .stupid-card .card-body {
  background-color: #fff;
}

/**
Job Application
 */
.job-application input::file-selector-button {
  display: none;
}
.job-application .form-control {
  box-shadow: unset;
  border-color: #E6E6E6;
}
.job-application .contact-details .far {
  color: #309AA7;
}

#members h1:after, #members .h1:after {
  content: "";
  display: block;
  vertical-align: center;
  width: 90px;
  margin: 10px 0;
  border-top: 3.5px solid #FFAA00;
}

.executive-card img {
  object-fit: cover;
  filter: grayscale(100%);
  transition: 0.3s all ease-in-out;
}
.executive-card h5, .executive-card .h5 {
  color: #000;
  transition: all 0.3s ease-in-out;
}
.executive-card h6, .executive-card .h6 {
  color: #000;
  transition: all 0.3s ease-in-out;
}
.executive-card:hover img {
  filter: unset;
  transform: scale(1.1);
}
.executive-card:hover h5, .executive-card:hover .h5 {
  color: #309AA7;
}
.executive-card:hover h6, .executive-card:hover .h6 {
  color: #FFAA00;
}

.executive-modal-body img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.executive-modal-body .text-secondary:after {
  content: "";
  display: block;
  vertical-align: center;
  width: 90px;
  margin: 10px 0;
  border-top: 3px solid #FFAA00;
}
.executive-modal-body .personal-info {
  border-right: solid 1px #E6E6E6;
}
@media (max-width: 1111px) {
  .executive-modal-body .personal-info {
    border-right: none;
    border-bottom: solid 1px #E6E6E6;
  }
}
.executive-modal-body .social-icons i {
  padding-right: 0.7rem;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.executive-modal-body .social-icons i:hover {
  color: #FFAA00;
}

#former-members .pp-img {
  filter: grayscale(100%);
  width: 100px;
  height: 100px;
  margin-right: 10px;
  transition: 0.3s;
  z-index: 2;
  object-fit: cover;
}
#former-members .social-icons {
  position: absolute;
  bottom: -2rem;
  transition: 0.3s;
}
#former-members .social-icons i {
  padding-right: 0.7rem;
  color: #000;
}
#former-members .social-icons i:hover {
  color: #6c757d;
}
@media (max-width: 887.98px) {
  #former-members .social-icons {
    position: static;
  }
}
#former-members .bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#former-members .card {
  /*cursor: pointer;*/
  position: relative;
  height: 160px;
  overflow: hidden;
}
#former-members .card:hover .pp-img {
  filter: grayscale(0%);
  transition: 0.3s;
}
#former-members .card:hover .social-icons {
  bottom: 1.6rem;
  transition: 0.3s;
}

.bg-green-light {
  background-color: #FFAA00;
}

body {
  background-color: #fff;
}

/*rtl:raw:
#org-desc::after {
    transform: rotateY(180deg);
}
#org-desc #org-desc-img-bg{
    transform: rotateY(180deg);
}
*/
.indented-text {
  display: flex;
  align-items: center;
}
@media (max-width: 1111px) {
  .indented-text {
    flex-direction: column;
  }
  .indented-text:before {
    content: "";
    width: 60px;
    height: 2px;
    margin-bottom: 10px;
    background-color: #FFAA00;
    border-radius: 50rem;
  }
}
@media (min-width: 1112px) {
  .indented-text:before {
    content: "";
    width: 60px;
    height: 2px;
    margin-right: 10px;
    background-color: #FFAA00;
    border-radius: 50rem;
  }
}

.text-hover-white:hover {
  color: #FFF !important;
}

.word-blue {
  color: #1B5EBE !important;
}

#org-desc {
  position: relative;
  padding-top: 4rem;
}
@media (max-width: 1111.98px) {
  #org-desc {
    padding-top: 3rem;
  }
}
#org-desc h1, #org-desc .h1 {
  font-size: 30px;
  color: #000;
  margin-bottom: 30px;
}
#org-desc p {
  margin-bottom: 50px;
}
#org-desc #org-desc-img {
  overflow: hidden;
  position: relative;
}
#org-desc #org-desc-img img {
  width: 100%;
}
@media (max-width: 887.98px) {
  #org-desc #org-desc-img:before {
    top: 39px;
    left: 0;
  }
}
#org-desc #org-static-img {
  height: 45%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-size: 125% !important;
  background-position-y: bottom !important;
  background-position-x: -97px !important;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.homepage-title {
  background-color: #309AA7;
  padding: 15px 30px 15px 15px;
  color: #fff;
  border-radius: 0 50rem 50rem 0;
}

.latest-news i, .latest-announcements i, .latest-publications i, .latest-jobs i, .department-show i {
  transition: all 0.3s;
}
.latest-news .homepage-title, .latest-announcements .homepage-title, .latest-publications .homepage-title, .latest-jobs .homepage-title, .department-show .homepage-title {
  font-size: 25px;
  font-weight: bolder;
}
.latest-news .homepage-more-link a, .latest-announcements .homepage-more-link a, .latest-publications .homepage-more-link a, .latest-jobs .homepage-more-link a, .department-show .homepage-more-link a {
  color: #FFAA00;
}
.latest-news .homepage-more-link a i, .latest-announcements .homepage-more-link a i, .latest-publications .homepage-more-link a i, .latest-jobs .homepage-more-link a i, .department-show .homepage-more-link a i {
  color: #FFAA00 !important;
  transition: all 0.3s;
}
.latest-news .homepage-more-link:hover a, .latest-announcements .homepage-more-link:hover a, .latest-publications .homepage-more-link:hover a, .latest-jobs .homepage-more-link:hover a, .department-show .homepage-more-link:hover a {
  color: #fff !important;
}
.latest-news .homepage-more-link:hover a i, .latest-announcements .homepage-more-link:hover a i, .latest-publications .homepage-more-link:hover a i, .latest-jobs .homepage-more-link:hover a i, .department-show .homepage-more-link:hover a i {
  color: #fff !important;
  transform: translate(5px, 0);
}

.filter-dropdown .filter-toggle i {
  height: 50px;
  width: 50px;
  background-color: #fff;
  border-radius: 50rem;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.filter-dropdown .filter-toggle i:before {
  color: #FFAA00;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-in-out;
}
.filter-dropdown .filter-toggle.show {
  transform: rotate(0) !important;
}
.filter-dropdown .filter-toggle.show i {
  background-color: #FFAA00;
}
.filter-dropdown .filter-toggle.show i:before {
  color: #fff;
}
.filter-dropdown .card {
  width: 400px;
}

#statsSection {
  background: black;
  background: linear-gradient(-90deg, black 0%, black 100%);
  margin-top: -1.5rem;
  position: relative;
}
#statsSection:before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-image: url("../img/stats-background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.1;
  /*rtl:raw:
      transform: scale(1.2,1);
  */
}
#statsSection .statistic-text {
  line-height: 69px;
}
#statsSection .statistic-text:before {
  content: "";
  display: block;
  vertical-align: center;
  width: 94px;
  border-top: 3px solid #FFAA00;
  border-radius: 25px;
}
#statsSection .stat-value {
  margin-top: -1rem;
}
#statsSection .stat-value:not(:first-child):before {
  content: "";
  height: 70%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 25%;
  background-color: #fff;
}
@media (max-width: 1111.98px) {
  #statsSection .statistic-text:before {
    margin: 0 auto;
  }
}
@media (max-width: 887.98px) {
  #statsSection .stat-value:not(:first-child):before {
    height: 1px;
    width: 40%;
    top: 16px;
    left: 31%;
    /*rtl:raw:
        right: 29%;
    */
  }
}

#training {
  margin-top: -275px;
  margin-bottom: 80px;
}
#training img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
#training #main-heading {
  font-size: 40px;
  color: #309AA7;
}
#training .col-lg-6:first-of-type {
  border-right: solid 1px #9FBDE6;
  padding: 0;
}
@media (max-width: 887.98px) {
  #training .col-lg-6:first-of-type {
    border: 0;
  }
}
#training .col-lg-6:first-of-type div {
  padding: 40px;
}
#training .col-lg-6:first-of-type h1, #training .col-lg-6:first-of-type .h1 {
  margin-bottom: 40px;
}
#training .col-lg-6:first-of-type p {
  margin-bottom: 39px;
}
#training .col-lg-6:last-of-type {
  padding: 30px 74px;
}
@media (max-width: 887.98px) {
  #training .col-lg-6:last-of-type {
    padding: 30px;
  }
}
#training .col-lg-6:last-of-type button {
  margin-top: 47px;
}
#training .col-lg-6:last-of-type .underline-text {
  margin-bottom: 35px;
}

#companies-section {
  margin-bottom: 138px;
}
#companies-section nav {
  margin-bottom: 41px;
}
#companies-section .nav-link {
  background-color: unset;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  color: #C5C5C5;
  border: 0;
}
#companies-section .nav-link:hover {
  border: 0;
}
#companies-section .nav-link:active {
  border: 0;
}
#companies-section .nav-tabs {
  border: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
#companies-section .nav-tabs .active {
  border: none;
  color: #000;
}
#companies-section .nav-tabs .active:after {
  content: "";
  display: block;
  vertical-align: center;
  width: 75px;
  margin: 10px 0 0 0;
  border-top: 3.5px solid #FFAA00;
}
#companies-section .tab-content img {
  height: 100px;
  object-fit: contain;
  filter: grayscale(100%);
}
#companies-section .tab-content img:hover {
  filter: grayscale(0);
}
@media (max-width: 887.98px) {
  #companies-section .tab-content img {
    margin-bottom: 45px;
  }
}

#mobile-app {
  position: relative;
  overflow: hidden;
  /*mobile view*/
  /*desktop view*/
}
#mobile-app .bg-left {
  position: absolute;
  filter: brightness(0) invert(1);
  opacity: 0.2;
  top: -100px;
  left: -250px;
  width: 383.18px;
}
#mobile-app .bg-right {
  position: absolute;
  filter: brightness(0) invert(1);
  opacity: 0.2;
  bottom: -150px;
  right: -250px;
  width: 873.97px;
  z-index: 1;
  transform: rotateY(180deg);
}
#mobile-app .col-lg-4 {
  z-index: 2;
  text-align: center;
}
#mobile-app .col-lg-4 .d-flex {
  justify-content: center;
}
#mobile-app .col-lg-4 .d-flex img {
  height: 35px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}
#mobile-app .col-lg-4 .d-flex h1, #mobile-app .col-lg-4 .d-flex .h1 {
  color: #fff;
  font-size: 30px;
}
#mobile-app .col-lg-3 {
  z-index: 2;
}
#mobile-app .col-lg-3 .download-box {
  border: solid 1px #fff;
}
#mobile-app .col-lg-3 .download-box h5, #mobile-app .col-lg-3 .download-box .h5 {
  color: #fff;
}
#mobile-app .col-lg-3 .download-box i {
  color: #fff;
  font-size: 35px;
}
#mobile-app .col-lg-3 .download-box:hover {
  background-color: #FFF;
}
#mobile-app .col-lg-3 .download-box:hover h5, #mobile-app .col-lg-3 .download-box:hover .h5, #mobile-app .col-lg-3 .download-box:hover i {
  color: #000;
}
#mobile-app .col-lg-3 img {
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: 100% 0;
}
@media (max-width: 887.98px) {
  #mobile-app .container {
    padding: 0 70px;
  }
  #mobile-app .container img {
    object-fit: cover;
    height: 500px;
  }
  #mobile-app .bg-left {
    display: none;
  }
}
@media (min-width: 1112px) {
  #mobile-app {
    height: 400px;
  }
  #mobile-app .row {
    height: 350px;
  }
  #mobile-app .col-lg-3 img {
    margin-top: -120px;
    height: 400px;
    object-fit: cover;
    object-position: 100% 0;
  }
  #mobile-app .col-lg-4 {
    text-align: left;
  }
  #mobile-app .col-lg-4 .d-flex {
    justify-content: flex-start;
  }
}

/*statistics section*/
#Statistics-section {
  background-color: #F2F5F8;
  padding: 50px 0;
}
#Statistics-section .card {
  margin-bottom: 2rem;
}
#Statistics-section .col-lg-3 .card {
  position: relative;
}
#Statistics-section .col-lg-3 .card i {
  font-size: 50px;
  color: #309AA7;
  margin: 0.4rem;
}
#Statistics-section .col-lg-3 .card h4, #Statistics-section .col-lg-3 .card .h4 {
  margin-right: 20%;
}
#Statistics-section .col-lg-3 .card h1, #Statistics-section .col-lg-3 .card .h1 {
  color: #309AA7;
}
#Statistics-section .col-lg-3 .card * {
  z-index: 2;
}
#Statistics-section .col-lg-3 .card .bg-image {
  z-index: -1;
  position: absolute;
  font-size: 90px;
  right: 3px;
  bottom: 3px;
  color: #F2F5F8;
}
@media (max-width: 887.98px) {
  #Statistics-section .col-lg-9 h1, #Statistics-section .col-lg-9 .h1, #Statistics-section .col-lg-9 h2, #Statistics-section .col-lg-9 .h2 {
    font-size: 9px;
  }
  #Statistics-section .col-lg-9 h4, #Statistics-section .col-lg-9 .h4 {
    font-size: 7px;
  }
}

/**
Projects
 */
.project .image {
  position: relative;
  width: auto;
}
.project .image .card-img-top {
  max-height: 200px;
  min-height: 150px;
  object-fit: cover;
}
.project .image span {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
.project a {
  color: #000;
  text-decoration: none;
}
.project a:hover {
  color: #309AA7;
}
.project .fa-stack .fa-circle {
  color: #F5F5F5;
}
.project .fa-stack .fa-stack-1x {
  color: #C5C5C5;
}
.project .circle .circles-text {
  font-size: 15px !important;
  font-weight: 700;
}
.project .attributes {
  color: #ACB0B2;
  font-size: 13px;
}

.project-show .attr-title {
  color: #ACB0B2;
}
.project-show .attr-value {
  color: #212529;
  font-size: 16px;
}
.project-show .circle .circles-text {
  font-size: 15px !important;
  font-weight: 700;
}
.project-show .sticky-top {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}
.project-show .sticky-top::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/**
Mapael
 */
.mapael .map {
  position: relative;
}

.mapael .mapTooltip {
  position: absolute;
  line-height: 100%;
  color: #747a80;
  display: none;
  font-size: 0.95rem;
  border-radius: 2px;
  padding: 0.7rem 1rem;
  z-index: 1000;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  line-height: 1.1em;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-value, .odometer.odometer-theme-default.odometer-animating-up .odometer-value {
  color: #FFAA00;
}
.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
  text-align: center;
}

.social-item {
  font-size: 25px;
  border-radius: 50%;
  color: #fff;
  transition: 0.5s all;
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-right: 5px;
}
.social-item:hover {
  background-color: #309AA7 !important;
}