#members {
    h1:after {
        content: "";
        display: block;
        vertical-align: center;
        width: 90px;
        margin: 10px 0;
        border-top: 3.5px solid $accent-color;
    }
}

.executive-card {
    img {
        object-fit: cover;
        filter: grayscale(100%);
        transition: 0.3s all ease-in-out;
    }

    h5 {
        color: $grey-70;
        transition: all 0.3s ease-in-out;
    }

    h6 {
        color: $grey-aa;
        transition: all 0.3s ease-in-out;
    }


    &:hover {
        img {
            filter: unset;
            transform: scale(1.1);
        }

        h5 {
            color: $primary;
        }

        h6 {
            color: $accent;
        }
    }
}

.executive-modal-body {
    img {
        width: 160px;
        height: 160px;
        object-fit: cover;
    }

    .text-secondary:after {
        content: "";
        display: block;
        vertical-align: center;
        width: 90px;
        margin: 10px 0;
        border-top: 3px solid $accent-color;
    }

    .personal-info {
        border-right: solid 1px $grey-e6;
        @media (max-width: 1111px) {
            border-right: none;
            border-bottom: solid 1px $grey-e6;
        }
    }

    .social-icons {
        i {
            padding-right: 0.7rem;
            color: $grey-aa;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $accent;
            }
        }
    }
}

#former-members {
    .pp-img {
        filter: grayscale(100%);
        width: 100px;
        height: 100px;
        margin-right: 10px;
        transition: 0.3s;
        z-index: 2;
        object-fit: cover;
    }

    .social-icons {
        position: absolute;
        bottom: -2rem;
        transition: 0.3s;

        i {
            padding-right: 0.7rem;
            color: $grey-aa;

            &:hover {
                color: $secondary;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .social-icons {
            position: static;
        }
    }

    .bg-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card {
        /*cursor: pointer;*/
        position: relative;
        height: 160px;
        overflow: hidden;

        &:hover {
            .pp-img {
                filter: grayscale(0%);
                transition: 0.3s;
            }

            .social-icons {
                bottom: 1.6rem;
                transition: 0.3s;
            }
        }
    }
}
