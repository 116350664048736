/**
Job Application
 */

.job-application {
    input::file-selector-button {
        display: none;
    }


    .form-control {
        box-shadow: unset;
        border-color: $grey-e6;
    }


    .contact-details {
        .far {
            color: $primary-color;
        }
    }

}
