.post-filter {
    .card-body {
        border-radius: 10px;
        font-weight: $font-weight-medium;
    }

    .filter-search-button {
        border-radius: 10px 0 0 10px;
        background-color: $grey-f6;

        i {
            color: $grey-aa;
        }
    }

    .filter-search-input {
        background-color: $grey-f6;
        color: $grey-70;
        font-weight: $font-weight-medium;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $grey-c5;
            opacity: 1; /* Firefox */
        }

        &::-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $grey-c5;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $grey-c5;
        }
    }

    .form-label {
        font-weight: $font-weight-medium;
    }

    .filter-clear-buttons {
        .btn {
            padding-top: 11px;
            padding-bottom: 9px;
        }
    }


}

.no-items {
    .no-items-svg {
        position: relative;

        &:before {
            font-family: 'Font Awesome 5 Pro';
            font-weight: $font-weight-regular;
            font-size: 39px;
            position: absolute;
            top: 32%;
            right: 36%;
            content: "\f002";
        }
    }
}
