@import '~compass-mixins/lib/compass/css3';

@mixin spinning-odometer($themeName) {
    .odometer.odometer-auto-theme,
    .odometer#{"." + $themeName} {
        @include inline-block;
        position: relative;

        .odometer-digit {
            @include inline-block;
            position: relative;

            .odometer-digit-spacer {
                @include inline-block;
                visibility: hidden;
            }

            .odometer-digit-inner {
                text-align: left;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
            }

            .odometer-ribbon {
                display: block;
            }

            .odometer-ribbon-inner {
                display: block;
                -webkit-backface-visibility: hidden;
            }

            .odometer-value {
                display: block;
                -webkit-transform: translateZ(0);

                &.odometer-last-value {
                    position: absolute;
                }
            }
        }

        &.odometer-animating-up {
            .odometer-ribbon-inner {
                -webkit-transition: -webkit-transform 2s;
                -moz-transition: -moz-transform 2s;
                -ms-transition: -ms-transform 2s;
                -o-transition: -o-transform 2s;
                transition: transform 2s;
            }

            &.odometer-animating .odometer-ribbon-inner {
                -webkit-transform: translateY(-100%);
                -moz-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                -o-transform: translateY(-100%);
                transform: translateY(-100%);
            }
        }

        &.odometer-animating-down {
            .odometer-ribbon-inner {
                -webkit-transform: translateY(-100%);
                -moz-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                -o-transform: translateY(-100%);
                transform: translateY(-100%);
            }

            &.odometer-animating .odometer-ribbon-inner {
                -webkit-transition: -webkit-transform 2s;
                -moz-transition: -moz-transform 2s;
                -ms-transition: -ms-transform 2s;
                -o-transition: -o-transform 2s;
                transition: transform 2s;

                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }
        }
    }
}

$themeName: "odometer-theme-default";

@include spinning-odometer($themeName);

.odometer.odometer-auto-theme, .odometer#{"." + $themeName} {
    line-height: 1.1em;


    &.odometer-animating-up .odometer-value {
        color: $accent-color;
    }

    .odometer-value {
        text-align: center;
    }
}
