/**
Pages
 */

.page-sidebar-menu {
    border-left: 1px solid $grey-e6;

    .dropstart {
        position: relative;

        .nav-link {
            font-size: 15px;
            padding-right: 0;
            padding-left: .9rem;
            font-weight: $font-weight-medium;
        }

        .dropdown-toggle {
            text-decoration: none;
            position: absolute;
            padding: 0;
            top: 0;
            right: 0.5rem;
        }
    }

    .sidebar-item {
        color: #000;
        padding-left: 15px;
        transition: all 0.3s;

        &:hover {
            color: $primary;
        }

        &.active {
            color: $primary;
        }
    }
}
