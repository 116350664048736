/**
Staff Directory
 */

.staff-directory {
    .table {
        font-weight: normal !important;

        thead, tbody, tfoot, tr, td, th {
            border-style: none;
        }

        thead {
            th {
                border-bottom-width: 0;
            }
        }

        tbody {
            background-color: $white;
            border-radius: 7px;

            .staff-records:last-child {
                th {
                    border-bottom-width: 0;
                }
            }
        }
    }

    .table > :not(:first-child) {
        border-top: unset;
    }

    .table > :not(caption) > * > * {
        padding: 1.25rem 1.25rem;
    }
}


// Quick fix for badges in buttons
.btn .filter-badge {
    position: relative;
    top: 22px;
}
